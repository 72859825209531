const businessPartnerDetailsKeys = [
  'maLicenceNumber',
  'legalBusinessName',
  'corporationNumber',
  'partnershipNumber',
  'jurisdiction',
  'residency',
  'isRegisteredBusinessName',
  'operatingName'
];

const businessAddKeys = [
  'streetAddress',
  'unitNumber',
  'city',
  'province',
  'country',
  'postalCode'
];

const businessContactKeys = [
  'website',
  'businessContactEmail',
  'businessContactPhoneNumber',
  'logo'
];

const maIdentyKeys = [
  'identificationFrontDocs',
  'identificationBackDocs',
  'identificationType',
  'identificationNumber'
];
const maSecondIdentyKeys = [
  'secondaryIdentificationType',
  'secondary_identification_number',
  'secondaryIdentificationFrontDocs',
  'secondaryIdentificationBackDocs'
];

export {
  businessPartnerDetailsKeys,
  businessAddKeys,
  businessContactKeys,
  maIdentyKeys,
  maSecondIdentyKeys
};
