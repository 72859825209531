import React from 'react';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { BsInfoCircle } from 'react-icons/bs';

const MaRequestPendingCard = ({ userPersonalDetails, isNotApprove = false }) => {
  const renderCard = () => {
    return (
      <Card>
        <CardBody>
          <h3>Waiting on your Mortgage Administrator to confirm your enrolment</h3>
          <p className="font-size-18 my-4 semi-bold">
            Thank you for completing your profile. Your profile is currently sitting with the
            Mortgage Administrator you enrolled with and awaiting their confirmation. Once that is
            completed, Opefund will be fully accessible.
          </p>
          <Label className=" d-flex align-items-center">
            <BsInfoCircle className="me-2" color="#0099FF" />
            Waiting on Mortgage Administrator's to approve.
          </Label>
        </CardBody>
      </Card>
    );
  };

  if (isNotApprove) {
    return renderCard();
  }

  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {userPersonalDetails.preferredName}!</h2>
      <Row>
        <Col md={6} lg={6}>
          {renderCard()}
        </Col>
      </Row>
    </Container>
  );
};

export default MaRequestPendingCard;
