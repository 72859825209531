const getAccountType = (id) => {
  const accountsType = [
    { id: 1, name: 'trust' },
    { id: 2, name: 'corporation' },
    { id: 3, name: 'individual' },
    { id: 4, name: 'joint' }
  ];
  const find = accountsType.find((item) => item.id === id);
  return find.name;
};

const benifiKeys = ['isBeneficiaries'];
const accountTypeKeys = ['accountType', 'accountName', 'isAccreditedInvestor'];
const trustInformationKeys = ['trustName', 'planType', 'accountNumber', 'isTrustMember'];
const lendingKnowledgeKeys = ['knowledgeRwgardingMA', 'knowledgeRwgardingInvestor'];
const incomeKeys = [
  'currentIncome',
  'isLevelOfIncome',
  'employment',
  'interest',
  'capitalGains',
  'otherIncome'
];
const availableCapitalKeys = ['availableCapitalNow', 'availableCapitalIn6Months'];
const investmentGoalKeys = ['investmentTimeHorizon', 'investmentObjectives'];

const corporateDetailKeys = [
  'coorporateLegalName',
  'coorporateOperatingName',
  'identificationNumber',
  'issuancePlace',
  'email'
];

const corporateAddressKeys = [
  'streetAddress',
  'unitNumber',
  'country',
  'province',
  'city',
  'postalCode'
];

const businessInfoKeys = ['contactPhoneNumber', 'contactEmail', 'website'];

export {
  getAccountType,
  benifiKeys,
  lendingKnowledgeKeys,
  incomeKeys,
  availableCapitalKeys,
  investmentGoalKeys,
  accountTypeKeys,
  corporateDetailKeys,
  corporateAddressKeys,
  businessInfoKeys,
  trustInformationKeys
};
