import React, { useEffect, useReducer, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Row
} from 'reactstrap';
import Contents from '../Contents';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Submission from 'pages/Profile/common/Submission';
import {
  ACCOUNT_KEY,
  APPROVED_KEY,
  BROKERAGE_DETAILS_KEY,
  PENDING_KEY,
  PERSONAL_INFO_KEY,
  PRINCIPAL_BROKER,
  REJECTED_KEY
} from 'constant';
import OnboardCard from '../Contents/OnboardCard';
import { getVerifiedBadge, getUnderReviewBadge } from 'pages/Profile/Helper';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';
import { getAccordionHeading } from './Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CODE':
      return { ...state, code: action.payload };
    case 'UPDATE_COMPLETE':
      return { ...state, completed: action.payload, code: 0 };
    default:
      return state;
  }
};

const PrincipalBroker = ({ userPersonalDetails, onRefetch }) => {
  const { isUnderReviewed, approveRequiredSections, approvedStatus } = userPersonalDetails;
  const [state, dispatch] = useReducer(reducer, {
    code: 0,
    completed: false
  });

  const { code, completed } = state;
  const navigate = useNavigate();
  const { state: params, pathname } = useLocation();
  const [open, setOpen] = useState();
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  useEffect(() => {
    const flag =
      userPersonalDetails.approvedStatus === PENDING_KEY ||
      userPersonalDetails.approvedStatus === APPROVED_KEY;
    if (userPersonalDetails?.isEsign && pathname === '/dashboard' && flag) {
      dispatch({ type: 'UPDATE_COMPLETE', payload: true });
    }
  }, []);

  const updateActiveCode = (activeCode) => {
    document.querySelector('.contain-height').scrollTo(0, 0);
    onRefetch();
    dispatch({ type: 'UPDATE_CODE', payload: activeCode });
  };

  const onActiveEmail = () => {
    let activeCode = 1;

    if (userPersonalDetails?.isEmailVerified) {
      activeCode = 2;
    }
    if (userPersonalDetails?.isPersonalInfo) {
      activeCode = 3;
    }
    if (userPersonalDetails?.isBrokerageInfo) {
      activeCode = 4;
    }

    dispatch({ type: 'UPDATE_CODE', payload: activeCode });
  };

  const renderContents = () => {
    return (
      <Outlet context={{ userPersonalDetails, profileKey: PRINCIPAL_BROKER, isProfile: true }} />
    );
  };

  const checkProfileStatus = () => {
    if (approvedStatus === APPROVED_KEY && !isUnderReviewed) {
      return getVerifiedBadge();
    } else if (approvedStatus === APPROVED_KEY && isUnderReviewed) {
      return getUnderReviewBadge();
    }
  };

  const getHeading = () => {
    if (pathname.includes('complete-profile')) {
      return <h2>Complete your Profile</h2>;
    }
    if (pathname.includes('approval'))
      return (
        <div className="mb-3 d-flex align-items-center">
          <h2>Approve Principle Broker</h2>
          {userPersonalDetails.approvedStatus === REJECTED_KEY && (
            <h5 className="ms-3 mb-0" style={{ color: '#EA6666' }}>
              REJECTED
            </h5>
          )}
        </div>
      );
    if (pathname.includes('user') || pathname.includes('profile'))
      return (
        <div className="d-flex align-items-center">
          <h2>Profile</h2>
          {checkProfileStatus()}
        </div>
      );
    if (pathname.includes('dashboard')) {
      return <h2>Hey {userPersonalDetails.preferredName}!</h2>;
    }
  };

  const renderOnBoardRejectCard = () => {
    if (userPersonalDetails?.approvedStatus === REJECTED_KEY && pathname === '/dashboard') {
      return <Submission userDetails={userPersonalDetails} onlyCard />;
    }
    if (pathname === '/dashboard' && userPersonalDetails?.approvedStatus !== REJECTED_KEY) {
      return <OnboardCard />;
    }
  };

  const onHandleBack = () => {
    if (!userPersonalDetails.isEsign) {
      return navigate(`/users/onboarding`);
    } else if (userPersonalDetails.approvedStatus === REJECTED_KEY) {
      return navigate(`/approvals/rejected`);
    } else {
      navigate(`/${pathname.includes('approval') ? 'approvals' : 'users'}/brokerage`);
    }
  };

  const renderBackButton = () => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return (
        <Button color="link" className="p-0 font-size-14" onClick={onHandleBack}>
          <FontAwesomeIcon icon={faArrowLeft} className="font-size-14 me-2" />
          Back to {pathname.includes('approval') ? 'approvals' : 'users'}
        </Button>
      );
    }
    return null;
  };

  const getSectionApprovalStatus = () => {
    let sectionName = null;

    if (pathname.includes('personal-info') || pathname.includes('personal-details')) {
      sectionName = PERSONAL_INFO_KEY;
    } else if (pathname.includes('accounts')) {
      sectionName = ACCOUNT_KEY;
    } else if (pathname.includes('brokerage-details')) {
      sectionName = BROKERAGE_DETAILS_KEY;
    }

    if (isUnderReviewed && pathname.includes('approval') && approveRequiredSections[sectionName]) {
      return true;
    }
    return false;
  };

  const isProfileReSubmitted = getSectionApprovalStatus();

  return (
    <div>
      {completed ? (
        <Submission userDetails={userPersonalDetails} />
      ) : (
        <div className={`${isProfileReSubmitted ? 'container-fluid' : 'container'}`}>
          <Row className="header-sticky">
            {!isTab && <Col md={1} lg={1} />}
            <Col className="content-heading">
              {renderBackButton()}
              {getHeading()}
            </Col>
          </Row>
          <Row>
            <Col md={1} lg={1} />
            {!isTab ? (
              <Col md={isProfileReSubmitted ? 2 : 3} lg={isProfileReSubmitted ? 2 : 3}>
                <Contents
                  completed={completed}
                  code={code}
                  clickHandler={(codeNum) => {
                    if (codeNum === 1 && !params) return;
                    updateActiveCode(codeNum);
                  }}
                  onActiveEmail={onActiveEmail}
                  userPersonalDetails={userPersonalDetails}
                />
              </Col>
            ) : null}

            <Col
              md={isTab ? 10 : !isProfileReSubmitted ? 7 : ''}
              lg={isTab ? 10 : !isProfileReSubmitted ? 7 : ''}
              className="profile-height">
              {isTab ? (
                <Accordion
                  open={open}
                  toggle={toggle}
                  className="content-accordion"
                  defaultOpen="1">
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <h4 className={`mb-0 ${!open ? 'text-primary' : ''}`}>
                        {open ? 'Contents' : getAccordionHeading(pathname)}
                      </h4>
                    </AccordionHeader>
                    <AccordionBody accordionId="1" className="p-0">
                      <Contents
                        completed={completed}
                        code={code}
                        clickHandler={(codeNum) => {
                          if (codeNum === 1 && !params) return;
                          updateActiveCode(codeNum);
                        }}
                        onActiveEmail={onActiveEmail}
                        userPersonalDetails={userPersonalDetails}
                      />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              ) : null}
              {renderContents()}
              {renderOnBoardRejectCard()}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default PrincipalBroker;
