import React, { useEffect } from 'react';
import { Field, Formik } from 'formik';
import { Col, Label, Row, Card, CardBody, Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Income from '../../common/Income';
import AccountTypes from '../../AccountTypes';
import LendingKnowledge from '../../common/LendingKnowledge';
import AvailableCapital from '../../common/AvailableCapital';
import InvestmentGoal from '../../common/InvestmentGoal';
import Corporation from '../../components/Corporation';
import Joint from '../../components/Joint';
import { accountTypes } from 'pages/Profile/common';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import { useQuery } from '@apollo/client';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import { ACCOUNT_KEY } from 'constant';
import {
  getAccountType,
  benifiKeys,
  lendingKnowledgeKeys,
  incomeKeys,
  availableCapitalKeys,
  investmentGoalKeys,
  accountTypeKeys,
  corporateDetailKeys,
  corporateAddressKeys,
  businessInfoKeys,
  trustInformationKeys
} from './Helper';
import { checkIfFormValueExistInData, checkIfMemberUpdated } from 'pages/Profile/Helper';
import Trust from '../Trust';
import {
  GET_BENEFICIARY_APPROVAL_DATA,
  GET_DIRECTOR_APPROVAL_DATA,
  GET_JOINT_MEMBER_APPROVAL_DATA,
  GET_TRUST_MEMBER_APPROVAL_DATA
} from 'pages/Investors/components/InvestorDetails/components/Accounts/components/Beneficiaries/GraphQL';
import BenifiMembers from '../../common/BenifiMembers';
import FormikRadioField from 'components/common/Fields/FormikRadioField';

const answer = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const NewDataFormContainer = (props) => {
  const { userPersonalDetails, accountData } = props;

  const { data, loading: updatedDataLoader } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: ACCOUNT_KEY,
      accountId: accountData?.id
    }
  });

  const { data: beneficiaryApprovalData, loading: beneficiaryDataLoader } = useQuery(
    GET_BENEFICIARY_APPROVAL_DATA,
    {
      variables: {
        accountId: accountData?.id
      }
    }
  );

  const { data: directorApprovalData, loading: directorLoader } = useQuery(
    GET_DIRECTOR_APPROVAL_DATA,
    {
      variables: {
        accountId: accountData?.id
      }
    }
  );

  const { data: jointMemberApprovalData, loading: jointMemberLoader } = useQuery(
    GET_JOINT_MEMBER_APPROVAL_DATA,
    {
      variables: {
        accountId: accountData?.id
      }
    }
  );

  const { data: trustMemberApprovalData, loading: trustMemberLoader } = useQuery(
    GET_TRUST_MEMBER_APPROVAL_DATA,
    {
      variables: {
        accountId: accountData?.id
      }
    }
  );

  if (
    updatedDataLoader ||
    beneficiaryDataLoader ||
    directorLoader ||
    jointMemberLoader ||
    trustMemberLoader
  ) {
    return null;
  }

  const updatedData = data?.getSectionUpdatedFields?.updatedFields || {};
  const beneficiaryUpdatedData = beneficiaryApprovalData?.getBeneficiaryApprovalData || [];
  const directorUpdatedData = directorApprovalData?.getDirectorApprovalData || [];
  const jointMemberUpdatedData = jointMemberApprovalData?.getJointMemberApprovalData || [];
  const trustMemberUpdatedData = trustMemberApprovalData?.getTrustMemberApprovalData || [];

  return (
    <NewDataForm
      {...props}
      updatedData={updatedData}
      beneficiaryUpdatedData={beneficiaryUpdatedData}
      directorUpdatedData={directorUpdatedData}
      jointMemberUpdatedData={jointMemberUpdatedData}
      trustMemberUpdatedData={trustMemberUpdatedData}
    />
  );
};

const NewDataForm = ({
  userPersonalDetails,
  accountData,
  setState,
  investorKey,
  setInvestorKey,
  onAccountChange,
  updatedData,
  beneficiaryUpdatedData,
  directorUpdatedData,
  jointMemberUpdatedData,
  trustMemberUpdatedData
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    onAccountChange('accountTypeCard', checkIfFormValueExistInData(updatedData, accountTypeKeys));
    onAccountChange(
      'lendingCriteriaCard',
      checkIfFormValueExistInData(updatedData, lendingKnowledgeKeys)
    );
    onAccountChange('incomeCard', checkIfFormValueExistInData(updatedData, incomeKeys));
    onAccountChange(
      'availableCapitalCard',
      checkIfFormValueExistInData(updatedData, availableCapitalKeys)
    );
    onAccountChange(
      'investmentGoalCard',
      checkIfFormValueExistInData(updatedData, investmentGoalKeys)
    );
    onAccountChange(
      'trustInformationCard',
      checkIfFormValueExistInData(updatedData, trustInformationKeys)
    );
    onAccountChange('trustMembersCard', checkIfMemberUpdated(trustMemberUpdatedData));
    onAccountChange('beneficiariesCard', checkIfMemberUpdated(beneficiaryUpdatedData));
    onAccountChange(
      'corporateDetailsCard',
      checkIfFormValueExistInData(updatedData, corporateDetailKeys)
    );
    onAccountChange(
      'corporateAddressCard',
      checkIfFormValueExistInData(updatedData, corporateAddressKeys)
    );
    onAccountChange(
      'businessInformationCard',
      checkIfFormValueExistInData(updatedData, businessInfoKeys)
    );
    onAccountChange('directorsCard', checkIfMemberUpdated(directorUpdatedData));
    onAccountChange('jointMembersCard', checkIfMemberUpdated(jointMemberUpdatedData));
  }, [updatedData, beneficiaryUpdatedData]);

  const checkIfAccountType = checkIfFormValueExistInData(updatedData, accountTypeKeys);
  const checkIfBenifiChanges = checkIfMemberUpdated(beneficiaryUpdatedData);
  const checkIfTrustMemberChanges = checkIfMemberUpdated(trustMemberUpdatedData);
  const checkIfJointMemberChanges = checkIfMemberUpdated(jointMemberUpdatedData);
  const checkIfDirectorMemberChanges = checkIfMemberUpdated(directorUpdatedData);

  const checkIfTrustInformationChanges = checkIfFormValueExistInData(
    updatedData,
    trustInformationKeys
  );

  const checkIfCorporateDetailChanges = checkIfFormValueExistInData(
    updatedData,
    corporateDetailKeys
  );
  const checkIfCorporateAddressChanges = checkIfFormValueExistInData(
    updatedData,
    corporateAddressKeys
  );
  const checkIfBusinessInfoChanges = checkIfFormValueExistInData(updatedData, businessInfoKeys);
  const checkIfLendingKnowledgeChange = checkIfFormValueExistInData(
    updatedData,
    lendingKnowledgeKeys
  );
  const checkIfIncomeChange = checkIfFormValueExistInData(updatedData, incomeKeys);
  const checkIfAvailableCapitalChange = checkIfFormValueExistInData(
    updatedData,
    availableCapitalKeys
  );
  const checkIfInvestmentGoalChange = checkIfFormValueExistInData(updatedData, investmentGoalKeys);

  const renderSubmitActionButtons = () => {
    if (pathname.includes('approval')) {
      return (
        <Button
          color="secondary"
          type="button"
          onClick={async () => {
            await setInvestorKey((prevKey) => investorKey + 1);
            setState((pre) => ({
              ...pre,
              accountData: null
            }));
          }}>
          Back to Account
        </Button>
      );
    }
    if (pathname.includes('complete-profile') || pathname.includes('profile')) {
      return renderSaveButton();
    }
  };

  return (
    <Formik
      initialValues={{
        accountType: getAccountType(updatedData?.accountTypeId || accountData?.accountTypeId),
        ...accountData,
        ...updatedData
      }}
      onSubmit={() => ''}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <AccountTypes isNewData={checkIfAccountType}>
            <div className="input-spacing">
              <Label>What is the type of this account ?</Label>
              <Row className="w-50">
                {accountTypes.map((item) => (
                  <Col md={6} lg={6} className="mb-1" key={item}>
                    <Field
                      size="lg"
                      key={item}
                      id={item}
                      name="accountType"
                      className="text-capitalize"
                      label={item}
                      value={values.accountType}
                      component={FormikRadioboxField}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </AccountTypes>
          {values.accountType === 'trust' && (
            <Trust
              userPersonalDetails={userPersonalDetails}
              isNewForm={trustMemberUpdatedData.length > 0}
              trustMembers={[...trustMemberUpdatedData]}
              isNewDataForm={true}
              isNewTrustInfomation={checkIfTrustInformationChanges}
              isNewData={checkIfTrustMemberChanges}
            />
          )}
          {values.accountType === 'corporation' && (
            <Corporation
              isNewData={{
                isCorporateDetailNewData: checkIfCorporateDetailChanges,
                isCorporateAddressNewData: checkIfCorporateAddressChanges,
                isBusinessInfoNewData: checkIfBusinessInfoChanges,
                isNewForm: directorUpdatedData.length > 0,
                isNewDataForm: true,
                isDirectorNewData: checkIfDirectorMemberChanges
              }}
              updateDirectors={() => ''}
              directors={[...directorUpdatedData]}
              userPersonalDetails={userPersonalDetails}
            />
          )}
          {values.accountType === 'joint' && (
            <Joint
              userPersonalDetails={userPersonalDetails}
              isNewForm={jointMemberUpdatedData.length > 0}
              jointMembers={[...jointMemberUpdatedData]}
              isNewDataForm={true}
              isNewData={checkIfJointMemberChanges}
            />
          )}
          {values.accountType && (
            <>
              <div>
                <Card className="my-4">
                  <CardBody className="p-4">
                    <h3 className="card-heading"> Beneficiaries</h3>
                    <div className="input-spacing">
                      <Label>
                        Are there any persons or entities other than the named person(s) on the
                        account, for whose benefit the account is being operated
                      </Label>
                      <div className="d-flex">
                        {answer.map(({ label, value }, index) => {
                          return (
                            <div key={label} className={index === 1 ? 'ms-3' : ''}>
                              <Field
                                name="isBeneficiaries"
                                type="radio"
                                label={label}
                                value={value}
                                checked={
                                  index === 1 ? !values.isBeneficiaries : values.isBeneficiaries
                                }
                                component={FormikRadioField}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {beneficiaryUpdatedData.length ? (
                  <BenifiMembers
                    title="Beneficiaries"
                    members={[...beneficiaryUpdatedData]}
                    isBenefi
                    isNewData={checkIfBenifiChanges}
                    isNewDataForm={true}
                  />
                ) : null}
              </div>
              <LendingKnowledge isNewData={checkIfLendingKnowledgeChange} />
              <Income isNewData={checkIfIncomeChange} onUpdateValidation={() => ''} />
              <AvailableCapital isNewData={checkIfAvailableCapitalChange} />
              <InvestmentGoal isNewData={checkIfInvestmentGoalChange} />
              <div className="mt-4 text-end">{renderSubmitActionButtons()}</div>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default NewDataFormContainer;
