import React from 'react';
import BusinessInfo from './components/BusinessInfo';
import CorporateAddress from './components/CorporateAddress';
import CorporateDetails from './components/CorporateDetails';
import Directors from './components/Directors';

const Corporation = ({
  updateDirectors,
  directors,
  userPersonalDetails,
  isNewData,
  isOldCorporateDetail,
  isOldCorporateAddress,
  isOldBusinessInformation,
  isOldDirectors
}) => {
  const {
    isCorporateDetailNewData,
    isCorporateAddressNewData,
    isBusinessInfoNewData,
    isNewForm,
    isNewDataForm,
    isDirectorNewData
  } = isNewData || {};

  return (
    <div>
      <CorporateDetails isOldData={isOldCorporateDetail} isNewData={isCorporateDetailNewData} />
      <CorporateAddress isOldData={isOldCorporateAddress} isNewData={isCorporateAddressNewData} />
      <BusinessInfo isOldData={isOldBusinessInformation} isNewData={isBusinessInfoNewData} />
      <Directors
        updateBenifi={updateDirectors}
        directors={directors}
        userPersonalDetails={userPersonalDetails}
        isNewForm={isNewForm}
        isNewDataForm={isNewDataForm}
        isNewData={isDirectorNewData}
        isOldData={isOldDirectors}
      />
    </div>
  );
};

export default Corporation;
