import React, { useEffect, useState } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import CustomPhoneInput from 'components/common/Fields/CustomPhoneInput';
import { memberBenifiValidation, directorsValidation } from 'pages/Profile/common';
import CombineErrorMessage from 'components/CombineError';
import {
  UPDATE_BENEFICIARY,
  UPDATE_DIRECTORS,
  UPDATE_JOINT_MEMBER,
  UPDATE_TRUST_MEMBER
} from 'pages/Profile/ProfileGraphql';
import { useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import { toast } from 'react-toastify';
import { getUpdateValuesFromObj } from 'pages/Profile/Helper';
import { getUser } from 'utils/UserDetails';

const MemberBenefiModal = ({
  open,
  close,
  title,
  editTitle,
  getData,
  values,
  isDirector,
  isBenefi,
  isJointMember,
  isTrustMember,
  refetch
}) => {
  const user = getUser();
  const [hasError, setHasError] = useState(false);
  const [updateBenifi, { loading }] = useMutation(UPDATE_BENEFICIARY);
  const [updateDirectors, { loading: updateLoading }] = useMutation(UPDATE_DIRECTORS);
  const [updateJointMember, { loading: updateJointLoading }] = useMutation(UPDATE_JOINT_MEMBER);
  const [updateTrustMember, { loading: updateTrustLoading }] = useMutation(UPDATE_TRUST_MEMBER);

  const getInitValues = () => {
    if (typeof values.id === 'number' && isDirector) {
      const { directorLegalName, directorLastName, directorPosition, directorPhoneNo } = values;
      return {
        beneficiaryLegalName: directorLegalName,
        beneficiaryLastName: directorLastName,
        beneficiaryRelationAccount: directorPosition,
        beneficiaryPhoneNo: directorPhoneNo
      };
    } else if (typeof values.id === 'number' && isJointMember) {
      const { jointLegalName, jointLastName, jointRelationAccount, jointPhoneNo } = values;
      return {
        beneficiaryLegalName: jointLegalName,
        beneficiaryLastName: jointLastName,
        beneficiaryRelationAccount: jointRelationAccount,
        beneficiaryPhoneNo: jointPhoneNo
      };
    } else if (typeof values.id === 'number' && isTrustMember) {
      const { trustLegalName, trustLastName, trustRelationAccount, trustPhoneNo } = values;
      return {
        beneficiaryLegalName: trustLegalName,
        beneficiaryLastName: trustLastName,
        beneficiaryRelationAccount: trustRelationAccount,
        beneficiaryPhoneNo: trustPhoneNo
      };
    }

    if (values) {
      const {
        beneficiaryLegalName,
        beneficiaryLastName,
        beneficiaryRelationAccount,
        beneficiaryPhoneNo,
        position
      } = values;
      const common = { beneficiaryLegalName, beneficiaryLastName, beneficiaryPhoneNo };
      return position ? { ...common, position } : { ...common, beneficiaryRelationAccount };
    } else {
      const common = { beneficiaryLegalName: '', beneficiaryLastName: '', beneficiaryPhoneNo: '' };
      return { ...common, beneficiaryRelationAccount: '' };
    }
  };

  const onSubmit = async (data) => {
    const castValues = memberBenifiValidation.cast(data);
    const allValues = getUpdateValuesFromObj(values, castValues);
    if (Object.keys(allValues).length) {
      if (typeof values.id === 'number' && isBenefi) {
        await updateBenifi({
          variables: {
            ...allValues,
            userId: user?.id,
            beneficiaryId: values.id,
            isUpdating: true
          }
        });
        refetch();
        return close(true);
      }
      if (typeof values.id === 'number' && isDirector) {
        return onUpdateDirectors(allValues);
      }
      if (typeof values.id === 'number' && isJointMember) {
        return onUpdateJointMember(allValues);
      }
      if (typeof values.id === 'number' && isTrustMember) {
        return onUpdateTrustMember(allValues);
      }
      if (values.id) {
        getData({ ...allValues, id: values.id });
      } else {
        getData(allValues);
      }
    }
  };

  const onUpdateDirectors = async (data) => {
    const {
      beneficiaryLegalName,
      beneficiaryLastName,
      beneficiaryRelationAccount,
      beneficiaryPhoneNo
    } = data;
    try {
      await updateDirectors({
        variables: {
          userId: user?.id,
          directorLegalName: beneficiaryLegalName,
          directorLastName: beneficiaryLastName,
          directorPosition: beneficiaryRelationAccount,
          directorPhoneNo: beneficiaryPhoneNo,
          directorId: values.id,
          isUpdating: true
        }
      });
      refetch();
      close(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onUpdateJointMember = async (data) => {
    const {
      beneficiaryLegalName,
      beneficiaryLastName,
      beneficiaryRelationAccount,
      beneficiaryPhoneNo
    } = data;
    try {
      await updateJointMember({
        variables: {
          userId: user?.id,
          jointLegalName: beneficiaryLegalName,
          jointLastName: beneficiaryLastName,
          jointRelationAccount: beneficiaryRelationAccount,
          jointPhoneNo: beneficiaryPhoneNo,
          jointMemberId: values.id,
          isUpdating: true
        }
      });
      refetch();
      close(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onUpdateTrustMember = async (data) => {
    const {
      beneficiaryLegalName,
      beneficiaryLastName,
      beneficiaryRelationAccount,
      beneficiaryPhoneNo
    } = data;
    try {
      await updateTrustMember({
        variables: {
          userId: user?.id,
          trustLegalName: beneficiaryLegalName,
          trustLastName: beneficiaryLastName,
          trustRelationAccount: beneficiaryRelationAccount,
          trustPhoneNo: beneficiaryPhoneNo,
          trustMemberId: values.id,
          isUpdating: true
        }
      });
      refetch();
      close(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateError = (err) => {
    setHasError(err);
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => close()} className="modal-dialog-centered">
        <ModalBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <h4 className="mb-4">{values ? editTitle : title}</h4>
          <Formik
            initialValues={getInitValues()}
            onSubmit={onSubmit}
            validationSchema={isDirector ? directorsValidation : memberBenifiValidation}
            enableReinitialize={true}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit}>
                <BenifiMemebersForm
                  isDirector={isDirector}
                  updateError={updateError}
                  close={close}
                  values={values}
                  loading={loading || updateLoading || updateJointLoading || updateTrustLoading}
                />
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

const BenifiMemebersForm = ({ isDirector, updateError, close, values, loading }) => {
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    updateError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.beneficiaryRelationAccount && touched.beneficiaryRelationAccount) ||
      (errors.beneficiaryLegalName && touched.beneficiaryLegalName) ||
      (errors.beneficiaryLastName && touched.beneficiaryLastName) ||
      (errors.beneficiaryRelationAccount && touched.beneficiaryRelationAccount) ||
      (errors.beneficiaryPhoneNo && touched.beneficiaryPhoneNo)
    );
  };
  return (
    <div>
      {isDirector && (
        <Row>
          <Col md={6} lg={6} className="input-spacing">
            <Field
              size="lg"
              id="position"
              name="beneficiaryRelationAccount"
              type="text"
              component={FormikInputField}
              label="Position"
              isError={false}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="input-spacing">
          <Field
            size="lg"
            id="beneficiaryLegalName"
            name="beneficiaryLegalName"
            type="text"
            component={FormikInputField}
            label="Legal First Name"
            isError={false}
            disabled={values.isSelf}
          />
        </Col>
        <Col className="input-spacing">
          <Field
            size="lg"
            id="beneficiaryLastName"
            name="beneficiaryLastName"
            type="text"
            component={FormikInputField}
            label="Legal Last Name"
            isError={false}
            disabled={values.isSelf}
          />
        </Col>
      </Row>
      {!isDirector && (
        <div className="input-spacing">
          <Field
            size="lg"
            id="beneficiaryRelationAccount"
            name="beneficiaryRelationAccount"
            type="text"
            component={FormikInputField}
            label="Relationship to Account Holder"
            isError={false}
            disabled={values.isSelf}
          />
        </div>
      )}
      <div className="input-spacing">
        <Field
          size="lg"
          id="beneficiaryPhoneNo"
          name="beneficiaryPhoneNo"
          label="Phone number"
          placeholder="Enter Your Mobile Number"
          component={CustomPhoneInput}
          isError={false}
          disabled={values.isSelf}
        />
      </div>
      <div className="d-flex justify-content-end mt-4 model-footer">
        <Button type="button" onClick={() => close()} outline size="sm">
          Cancel
        </Button>
        {loading ? (
          <Loader />
        ) : (
          <Button type="submit" color="primary" className="ms-3" size="sm">
            {values.id ? 'Update' : 'Save'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default MemberBenefiModal;
