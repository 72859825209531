import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Label } from 'reactstrap';
import FormikCheckboxField from 'components/common/Fields/FormikCheckboxField';
import CombineErrorMessage from 'components/CombineError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const level = ['Extensive', 'Moderate', 'None'];

const LendingKnowledge = ({ isNewData, isOldData }) => {
  const [hasError, setHasError] = useState(false);

  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.knowledgeRwgardingMA && touched.knowledgeRwgardingMA) ||
      (errors.knowledgeRwgardingInvestor && touched.knowledgeRwgardingInvestor)
    );
  };

  return (
    <div>
      <Card className="my-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0 position-relative">
            <h3 className="card-heading">Lending Knowledge</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          <div className="input-spacing">
            <Label>
              What is your level of knowledge regarding mortgage administration companies and other
              private income-based investments?
            </Label>
            <div className="d-flex">
              {level.map((item, index) => {
                return (
                  <div className="me-4" key={item}>
                    <Field
                      key={item}
                      type="radio"
                      value={item}
                      checked={values.knowledgeRwgardingMA === item}
                      name="knowledgeRwgardingMA"
                      label={item}
                      id={`${item}${index}`}
                      component={FormikCheckboxField}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="input-spacing">
            <Label id="errorScroll">
              What is your level of knowledge regarding private investment?
              <span className="text-muted">
                (eg. securities of private operating companies or development projects?)
              </span>
            </Label>
            <div className="d-flex">
              {level.map((item, index) => {
                return (
                  <div className="me-4" key={item}>
                    <Field
                      name="knowledgeRwgardingInvestor"
                      key={item}
                      type="radio"
                      value={item}
                      id={`${item}`}
                      checked={values.knowledgeRwgardingMA === item}
                      label={item}
                      component={FormikCheckboxField}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default LendingKnowledge;
