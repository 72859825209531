import React, { useEffect } from 'react';
import Avatar from 'components/Avatar';
import { Button } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { isEmpty, cloneDeep } from 'lodash';
import { ACCEPT_REJECT_INVITATION, GET_INVITATION_REQUEST_LIST } from 'pages/Agent/Graphql';
import { getUser } from 'utils/UserDetails';
import { ACCEPT_TYPE, INVITE_TYPE, REJECT_TYPE } from 'constant';
import PageLoader from 'components/PageLoader';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { INVITE_REQUEST_SUBSCRIPTION } from 'pages/FindMortgageAdmin/GraphQL';

const MAInvitations = ({ fetchMa }) => {
  const user = getUser();
  const { data, loading, refetch, subscribeToMore } = useQuery(GET_INVITATION_REQUEST_LIST, {
    variables: {
      invitedTo: user.id,
      inviteRequestType: INVITE_TYPE,
      actionType: 'pending'
    }
  });
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });

  const [acceptRejectInvitation, { loading: acceptLoading }] =
    useMutation(ACCEPT_REJECT_INVITATION);

  useEffect(() => {
    const unsubInviteRequest = subscribeInviteRequest();
    return () => {
      unsubInviteRequest();
    };
  }, []);

  const subscribeInviteRequest = () => {
    return subscribeToMore({
      document: INVITE_REQUEST_SUBSCRIPTION,
      variables: {
        userId: parseInt(user.id),
        roleId: parseInt(user.roleId)
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || isEmpty(prev)) return;
        if (!subscriptionData.data) return prev;

        const { inviteRequestSubscription } = subscriptionData.data;
        const newData = cloneDeep(prev);
        const { operation, invitedBy, invitedTo } = inviteRequestSubscription;
        const { invitesRequests } = prev.getInvitationsRequests;

        if (operation === 'ADD') {
          if (user.id === invitedBy || user.id === invitedTo) {
            newData.getInvitationsRequests.invitesRequests.push(inviteRequestSubscription);
          }
        }
        return newData;
      }
    });
  };

  const maRequests = data?.getInvitationsRequests
    ? data?.getInvitationsRequests.invitesRequests
    : [];

  const onAcceptRejectInvitation = async (id, type) => {
    try {
      await acceptRejectInvitation({
        variables: {
          invitationRequestId: Number(id),
          actionType: type
        }
      });
      refetch();
      fetchMa();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      {loading && <PageLoader />}
      {maRequests?.map((mac) => (
        <div
          key={mac.id}
          className="d-flex justify-content-between p-4 mb-3"
          style={{ background: '#F8F8F8' }}>
          <div className="d-flex align-items-center">
            <div className="me-3">
              <Avatar
                fname={mac.inviteBy.firstName}
                lname={mac.inviteBy.lasttName}
                size={60}
                fontsize="20"
              />
            </div>
            <div>
              <h5 className="font-size-16 mb-1">
                <span
                  className={`${
                    isMobile ? 'show-email-text-ellipses' : ''
                  } font-size-18`}>{`${mac.inviteBy.firstName} ${mac.inviteBy.lastName}`}</span>
              </h5>
              <p className={`${isMobile ? 'show-email-text-ellipses' : ''} m-0`}>
                {mac.inviteBy.email}
              </p>
            </div>
          </div>
          <div>
            <Button
              size="sm"
              className="py-1"
              color="primary"
              block
              disabled={acceptLoading}
              onClick={() => onAcceptRejectInvitation(mac.id, ACCEPT_TYPE)}>
              Accept
            </Button>
            <Button
              size="sm"
              className="py-1 mt-2"
              color="muted"
              outline
              block
              disabled={acceptLoading}
              onClick={() => onAcceptRejectInvitation(mac.id, REJECT_TYPE)}>
              Decline
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MAInvitations;
