import React from 'react';
import Avatar from 'components/Avatar';
import infoIcon from 'assets/icons/info.svg';
import { PENDING_KEY } from 'constant';
import { formatContactNumber } from 'utils';

const MaOfInvestors = ({ maList }) => {
  if (!maList.length) {
    return <p className="text-center mt-2">Not connected with MA</p>;
  }

  const getName = (fName, lName, operatingName, legalBusinessName) => {
    if (operatingName) return operatingName;
    if (legalBusinessName) return legalBusinessName;
    return `${fName} ${lName}`;
  };

  return (
    <>
      {maList?.map((item) => {
        const {
          businessContactPhoneNumber,
          firstName,
          lastName,
          id,
          logo,
          operatingName,
          businessName,
          requestStatus
        } = item;
        return (
          <div
            key={id}
            // className="d-flex justify-content-between p-4 mb-3"
            className="p-4 mb-3"
            style={{ background: '#F8F8F8' }}>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <Avatar fname={firstName} lname={lastName} image={logo} size={60} fontsize="20" />
              </div>
              <div>
                <h5 className="font-size-16 mb-1">
                  <span className="font-size-18">
                    {getName(firstName, lastName, operatingName, businessName)}
                  </span>
                </h5>
                <p className="mb-0 text-muted">{formatContactNumber(businessContactPhoneNumber)}</p>
                {requestStatus === PENDING_KEY ? (
                  <p className="mb-0 mt-2 d-flex align-items-center">
                    <img src={infoIcon} alt="info icon" style={{ height: '20px', width: '20px' }} />
                    <span className="ms-2">Waiting on MA to accept invite</span>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MaOfInvestors;
