/*Each profile of diffrent role*/
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import BrokerProfile from './components/Broker';
import InvestorProfile from './components/Investor';
import MortgageAdmin from './components/MortgageAdmin';
import PrincipalBroker from './components/PrincipalBroker';
import { RoleContext } from 'context/Role';
import {
  MA_ROLE_KEY,
  INVESTOR_ROLE_KEY,
  BROKER_ROLE_KEY,
  PB_ROLE_KEY,
  REJECTED_KEY,
  PENDING_KEY
} from 'constant';
import { useLocation, useParams } from 'react-router-dom';
import { getUser } from 'utils/UserDetails';
import { GET_USER_DETAIL_BY_ID, USERS_LIST_SUBSCRIPTION } from 'pages/Profile/ProfileGraphql';
import PageLoader from 'components/PageLoader';
import { Helmet } from 'react-helmet';
import ComingSoon from 'pages/ComingSoon';

const Profile = () => {
  const roles = useContext(RoleContext);
  const [investorKey, setInvestorKey] = useState(0);
  const { id, title } = useParams();
  const { pathname } = useLocation();
  const user = getUser();
  const splitPath = pathname.split('/');

  const userPath = pathname.includes('complete-profile') || pathname.includes('profile');

  const { data, loading, refetch, subscribeToMore } = useQuery(GET_USER_DETAIL_BY_ID, {
    variables: {
      userId: parseInt(id || user.id)
    }
  });

  useEffect(() => {
    onRefetchDetails();
    const unsubUsersList = subscribeUsersList();
    return () => {
      unsubUsersList();
    };
  }, [pathname]);

  const subscribeUsersList = () =>
    subscribeToMore({
      document: USERS_LIST_SUBSCRIPTION,
      variables: {
        userId: parseInt(user.id),
        roleId: parseInt(user.roleId)
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || isEmpty(prev)) return;
        if (!subscriptionData.data) return prev;

        if (pathname === '/dashboard') {
          window.location.reload();
        }
      }
    });

  const userPersonalDetails = data?.getUserPersonalDetailById ? data.getUserPersonalDetailById : {};

  const onRefetchDetails = () => {
    refetch({
      userId: parseInt(id || user.id)
    });
  };

  const getProfile = () => {
    let key = title || roles.roleKey;
    switch (key) {
      case INVESTOR_ROLE_KEY:
        return (
          <InvestorProfile
            investorKey={investorKey}
            userPersonalDetails={userPersonalDetails}
            onRefetch={onRefetchDetails}
            setInvestorKey={setInvestorKey}
          />
        );
      case BROKER_ROLE_KEY:
        return (
          <BrokerProfile userPersonalDetails={userPersonalDetails} onRefetch={onRefetchDetails} />
        );
      case MA_ROLE_KEY:
        return (
          <MortgageAdmin userPersonalDetails={userPersonalDetails} onRefetch={onRefetchDetails} />
        );
      case PB_ROLE_KEY:
        return (
          <PrincipalBroker userPersonalDetails={userPersonalDetails} onRefetch={onRefetchDetails} />
        );
      default:
        break;
    }
  };

  if (loading || !roles?.roleTitle) {
    return <PageLoader />;
  }

  const renderTitle = () => {
    if (pathname.includes('complete-profile') || pathname.includes('dashboard')) {
      return 'Complete your Profile | Openfund';
    }
    if (
      pathname.includes('user') ||
      pathname.includes('approval') ||
      pathname.includes('profile')
    ) {
      return 'Profile | Openfund';
    }
    return null;
  };

  if (userPath && splitPath.length === 2 && pathname !== '/dashboard') {
    return <ComingSoon />;
  }

  if (
    userPath &&
    userPersonalDetails.isEsign &&
    userPersonalDetails.approvedStatus === PENDING_KEY &&
    userPersonalDetails.approvedStatus !== REJECTED_KEY &&
    roles.roleKey !== BROKER_ROLE_KEY
  ) {
    return <ComingSoon />;
  }

  return (
    <div>
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>

      {getProfile()}
    </div>
  );
};

export default Profile;
