import React from 'react';
import OpenlendModal from 'components/OpenlendModal';
import { Button } from 'reactstrap';
import Loader from 'components/Loader';

const RemoveModal = ({ isOpen, toggle, onClick, loading, name }) => {
  return (
    <OpenlendModal
      modal={isOpen}
      toggle={toggle}
      headerTitle="Remove Account"
      className="max-width">
      <p className="font-size-18 mb-4">Do you want to remove {name}?</p>
      <div className="d-flex justify-content-end mt-3">
        <Button color="muted" type="button" outline className="me-4" size="sm" onClick={toggle}>
          Cancel
        </Button>
        {loading ? (
          <Loader />
        ) : (
          <Button color="danger" type="button" size="sm" onClick={onClick}>
            Remove
          </Button>
        )}
      </div>
    </OpenlendModal>
  );
};

export default RemoveModal;
