import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import BusinessAddress from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessAddress';
import BusinessContact from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessContact';
import BusinessDetails from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessDetails';
import BusinessType from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessType';
import Loader from 'components/Loader';

const NewBrokerageForm = ({
  initValues,
  onApproveSection,
  newFormData,
  checkBusinessDetails,
  checkBusinessAddress,
  checkBusinessContact
}) => {
  const { pathname } = useLocation();
  const isPathApproval = pathname.includes('approval');

  const renderFormActionButtons = (isSubmitting) => {
    if (isPathApproval) {
      return (
        <div className="mt-4 text-end">
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2"
            disabled={isSubmitting}>
            Reject
          </Button>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button
              color="primary"
              {...{
                type: 'submit',
                name: 'approveValue',
                value: true
              }}>
              Approve Section
            </Button>
          )}
        </div>
      );
    }
  };

  return (
    <Formik
      initialValues={{ ...initValues, ...newFormData }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize={true}
      onSubmit={onApproveSection}>
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && pathname.includes('approval')) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          <BusinessType title="Business Type" onUpdateValidation={() => ''} />
          <BusinessDetails isNewData={checkBusinessDetails} licenseKey="brokerageLicenseNumber" />
          <BusinessAddress isNewData={checkBusinessAddress} />
          <BusinessContact isNewData={checkBusinessContact} />
          {renderFormActionButtons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default NewBrokerageForm;
