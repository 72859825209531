import React from 'react';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { BsInfoCircle } from 'react-icons/bs';
import MAList from '../../MAList';

const MaRejectionCard = ({ userPersonalDetails, children }) => {
  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {userPersonalDetails.preferredName}!</h2>
      <Row>
        <Col md={6} lg={6}>
          <Card>
            <CardBody>
              <h3>Please select another Mortgage Administrator</h3>
              <p className="font-size-18 my-4 semi-bold">
                The mortgage administror has either not approved or removed you from their roster.
                Investors that are not licensed through FSRA will need to invest through a Mortgage
                Administrator, Mortgage Investment Firm or Brokerage. Before we can submit your
                account for review, you will need to enrol with a Mortgage Administrator.
              </p>
              <Label className=" d-flex align-items-center">
                <BsInfoCircle className="me-2" color="#0099FF" />
                Here are a few recommend Administrator
              </Label>
              {children}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaRejectionCard;
