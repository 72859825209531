import { gql } from '@apollo/client';

const TRUST_ACCOUNT_MUTATION = gql`
  mutation AddTrustAccount($trustAccountInput: TrustAccountInput!, $userId: Int!) {
    addTrustAccount(trustAccountInput: $trustAccountInput, userId: $userId)
  }
`;

const INDIVIDUAL_ACCOUNT_MUTATION = gql`
  mutation AddIndividualMemberAccount(
    $individualAccountInput: IndividualAccountInput!
    $userId: Int!
  ) {
    addIndividualMemberAccount(individualAccountInput: $individualAccountInput, userId: $userId)
  }
`;

const JOINT_ACCOUNT_MUTATION = gql`
  mutation AddJointMemberAccount($jointAccountInput: JointAccountInput!, $userId: Int!) {
    addJointMemberAccount(jointAccountInput: $jointAccountInput, userId: $userId)
  }
`;

const ADD_BENEFICIARIES = gql`
  mutation AddBeneficiary(
    $userId: Int
    $accountTypeId: Int
    $investorAccountId: Int
    $beneficiaryLegalName: String
    $beneficiaryLastName: String
    $beneficiaryRelationAccount: String
    $beneficiaryPhoneNo: String
  ) {
    addBeneficiary(
      userId: $userId
      accountTypeId: $accountTypeId
      beneficiaryLegalName: $beneficiaryLegalName
      beneficiaryLastName: $beneficiaryLastName
      beneficiaryRelationAccount: $beneficiaryRelationAccount
      beneficiaryPhoneNo: $beneficiaryPhoneNo
      investorAccountId: $investorAccountId
    )
  }
`;

const ADD_DIRECTORS = gql`
  mutation AddDirectorDetails(
    $userId: Int
    $accountTypeId: Int
    $investorAccountId: Int
    $directorLegalName: String
    $directorLastName: String
    $directorPosition: String
    $directorPhoneNo: String
  ) {
    addDirectorDetails(
      userId: $userId
      accountTypeId: $accountTypeId
      investorAccountId: $investorAccountId
      directorLegalName: $directorLegalName
      directorLastName: $directorLastName
      directorPosition: $directorPosition
      directorPhoneNo: $directorPhoneNo
    )
  }
`;

const ADD_CORPORATE_ACCOUNT = gql`
  mutation AddCorporateAccountDetails(
    $userId: Int!
    $corporateAccountInput: CorporateAccountInput!
  ) {
    addCorporateAccountDetails(userId: $userId, corporateAccountInput: $corporateAccountInput)
  }
`;

const FETCH_ACCOUNTS = gql`
  query getInvestorAccounts($userId: Int) {
    getInvestorAccounts(userId: $userId)
  }
`;

const GET_FILE_PRESIGNED_URL = gql`
  mutation GetFileApiPresignedUrl($userId: Int!, $filePath: String!, $fileType: String!) {
    getFileApiPresignedUrl(userId: $userId, filePath: $filePath, fileType: $fileType)
  }
`;

const UPDATE_INDIVIDUAL_ACCOUNT = gql`
  mutation UpdateIndividualAccount(
    $userId: Int!
    $investorAccountId: Int!
    $individualAccountInput: IndividualAccountInput!
    $isUpdating: Boolean
  ) {
    updateIndividualAccount(
      userId: $userId
      investorAccountId: $investorAccountId
      individualAccountInput: $individualAccountInput
      isUpdating: $isUpdating
    )
  }
`;

const UPDATE_TRUST_ACCOUNT = gql`
  mutation UpdateTrustAccount(
    $userId: Int!
    $investorAccountId: Int!
    $trustAccountInput: TrustAccountInput!
    $isUpdating: Boolean
  ) {
    updateTrustAccount(
      userId: $userId
      investorAccountId: $investorAccountId
      trustAccountInput: $trustAccountInput
      isUpdating: $isUpdating
    )
  }
`;

const UPDATE_JOINT_ACCOUNT = gql`
  mutation UpdateJointAccount(
    $userId: Int!
    $investorAccountId: Int!
    $jointAccountInput: JointAccountInput!
    $isUpdating: Boolean
  ) {
    updateJointAccount(
      userId: $userId
      investorAccountId: $investorAccountId
      jointAccountInput: $jointAccountInput
      isUpdating: $isUpdating
    )
  }
`;

const UPDATE_CORPORATION_ACCOUNT = gql`
  mutation UpdateCorporateAccountDetails(
    $userId: Int!
    $corporateMemberId: Int!
    $investorAccountId: Int!
    $corporateAccountInput: CorporateAccountInput!
    $isUpdating: Boolean
  ) {
    updateCorporateAccountDetails(
      userId: $userId
      corporateMemberId: $corporateMemberId
      investorAccountId: $investorAccountId
      corporateAccountInput: $corporateAccountInput
      isUpdating: $isUpdating
    )
  }
`;

const ADD_JOINT_MEMBER = gql`
  mutation addJointMember(
    $userId: Int
    $accountTypeId: Int
    $investorAccountId: Int
    $jointLegalName: String
    $jointLastName: String
    $jointRelationAccount: String
    $jointPhoneNo: String
  ) {
    addJointMember(
      userId: $userId
      accountTypeId: $accountTypeId
      jointLegalName: $jointLegalName
      jointLastName: $jointLastName
      jointRelationAccount: $jointRelationAccount
      jointPhoneNo: $jointPhoneNo
      investorAccountId: $investorAccountId
    )
  }
`;

const ADD_TRUST_MEMBER = gql`
  mutation addTrustMember(
    $userId: Int
    $accountTypeId: Int
    $investorAccountId: Int
    $trustLegalName: String
    $trustLastName: String
    $trustRelationAccount: String
    $trustPhoneNo: String
  ) {
    addTrustMember(
      userId: $userId
      accountTypeId: $accountTypeId
      trustLegalName: $trustLegalName
      trustLastName: $trustLastName
      trustRelationAccount: $trustRelationAccount
      trustPhoneNo: $trustPhoneNo
      investorAccountId: $investorAccountId
    )
  }
`;

export {
  TRUST_ACCOUNT_MUTATION,
  ADD_BENEFICIARIES,
  ADD_CORPORATE_ACCOUNT,
  JOINT_ACCOUNT_MUTATION,
  INDIVIDUAL_ACCOUNT_MUTATION,
  UPDATE_INDIVIDUAL_ACCOUNT,
  ADD_DIRECTORS,
  FETCH_ACCOUNTS,
  GET_FILE_PRESIGNED_URL,
  UPDATE_TRUST_ACCOUNT,
  UPDATE_JOINT_ACCOUNT,
  UPDATE_CORPORATION_ACCOUNT,
  ADD_JOINT_MEMBER,
  ADD_TRUST_MEMBER
};
