import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import BenifiMembers from '../../../../common/BenifiMembers';
import MemberBenefiModal from '../../../../common/MemberBenefiModal';
import { useLazyQuery } from '@apollo/client';
import { GET_DIRECTORS } from 'pages/Investors/components/InvestorDetails/components/Accounts/components/Directors/GraphQL';
import { getUser } from 'utils/UserDetails';
import { selfDirector } from '../../../../index';
import { getUpdatedMembers } from 'pages/Profile/Helper';

const Directors = ({
  updateBenifi,
  directors,
  userPersonalDetails,
  isNewForm,
  isNewDataForm,
  isNewData,
  isOldData
}) => {
  const [state, setState] = useState({
    // directors: [],
    directorModal: false,
    editDirectorsData: '',
    hasError: false
  });

  const { values, touched, errors, setFieldValue } = useFormikContext();

  const user = getUser();

  const [fetchDirectors, { data, refetch }] = useLazyQuery(GET_DIRECTORS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      investorAccountId: values.id
    }
  });

  const allDirectors = data?.getDirectorDetails?.directors
    ? data?.getDirectorDetails?.directors
    : [];

  const { directorModal, editDirectorsData, hasError } = state;

  useEffect(() => {
    if (values?.id) {
      fetchDirectors();
      updateBenifi([]);
    } else {
      setFieldValue('isDirector', true);
    }
  }, []);

  useEffect(() => {
    const err = checkError();
    setState((pre) => ({
      ...pre,
      hasError: err
    }));
  }, [touched, errors]);

  const getDirectors = (data) => {
    if (data.id) {
      const exclude = directors.filter((item) => item.id !== data.id);
      const matchedDirectors = directors.find((item) => item.id === data.id);
      updateBenifi([...exclude, { ...matchedDirectors, ...data, id: data.id }]);
      setState((pre) => ({
        ...pre,
        // directors: [...find, { ...data, id: data.id }],
        directorModal: false,
        editDirectorsData: ''
      }));
    } else {
      const read = { ...data, id: uuidv4() };
      updateBenifi([...directors, { ...read }]);
      setState((pre) => ({
        ...pre,
        directorModal: false,
        editDirectorsData: ''
      }));
    }
  };

  const onEditDirectors = (id) => {
    const find = [...directors, ...allDirectors].filter((item) => item.id === id);
    setState((pre) => ({
      ...pre,
      editDirectorsData: find[0],
      directorModal: true
    }));
  };

  const onRemoveDirectors = (id) => {
    const find = directors.filter((item) => item.id !== id);
    if (!find?.length) {
      setFieldValue('isDirector', false);
    }
    updateBenifi([...find]);
  };

  const onAddDirectors = () => {
    setState((pre) => ({
      ...pre,
      directorModal: true
    }));
  };

  const onHandleClick = (e) => {
    const { value } = e.target;
    if (value === 'false') {
      updateBenifi([]);
    }
    if (value === 'true') {
      updateBenifi([{ ...selfDirector(user) }]);
    }
    setState((pre) => ({
      ...pre,
      directorModal: value === 'true'
    }));
  };

  const checkError = () => {
    return errors.isDirector && touched.isDirector;
  };

  const onCloseDirectorModal = (flag = false) => {
    const allDirct = directors.length || allDirectors.length;
    if (allDirct && !flag) {
      setFieldValue('isDirector', true);
    } else {
      setFieldValue('isDirector', flag);
    }

    setState((pre) => ({
      ...pre,
      directorModal: false,
      editDirectorsData: ''
    }));
  };

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const members = isNewForm ? [...directors] : [...allDirectors, ...directors];

  const updatedMembers = getUpdatedMembers(members, isNewDataForm);

  return (
    <>
      {directorModal ? (
        <MemberBenefiModal
          title="Additional Directors Information"
          editTitle="Edit Directors Information"
          open={directorModal}
          close={onCloseDirectorModal}
          getData={getDirectors}
          values={editDirectorsData}
          isDirector
          refetch={refetch}
        />
      ) : null}
      <div className="mt-3">
        <BenifiMembers
          title="Directors"
          addButtonTitle="Add Directors"
          members={[...updatedMembers]}
          onEdit={onEditDirectors}
          onRemove={onRemoveDirectors}
          onAddMember={onAddDirectors}
          formKey={{
            firstName: 'directorLegalName',
            lastName: 'directorLastName',
            relation: 'directorPosition',
            phoneNumber: 'directorPhoneNo'
          }}
          isDirector
          isNewData={isNewData}
          isOldData={isOldData}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default Directors;
