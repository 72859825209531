import React, { useContext, useEffect, useReducer } from 'react';
import { Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { filter } from 'lodash';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { RoleContext } from 'context/Role';
import { getUser } from 'utils/UserDetails';
import Address from './component/Address';
import ErrorScroll from 'components/ErrorScroll';
import Licensing from './component/LicenseAdmin';
import PreviousAddress from './component/PreviousAddress';
import AdditionalInfo from './component/AdditionalInfo';
import Identification from 'pages/Profile/common/Identy';
import ProfilePicture from 'pages/Profile/common/ProfilePic';
import { personalInfoValues } from 'pages/Profile/common';
import { ADD_PERSONAL_INFO, FETCH_APPROVING_PERSONAL_INFO } from './PersonalInfoGraphQL';
import ApprovePersonalInfo from 'pages/Profile/common/ApprovePersonalInfo';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import {
  PERSONAL_INFO_KEY,
  REQUEST_TYPE,
  MA_ROLE_KEY,
  ACCOUNT_KEY,
  PENDING_KEY,
  REJECTED_KEY
} from 'constant';
import { personalInfoCommonValidation } from 'pages/OnBoarding/components/common';
import {
  checkIfFormValueExistInData,
  getUpdateValuesFromObj,
  personalInfoKeys,
  updateInvestorApproveSection
} from 'pages/Profile/Helper';
import { licenseValidation, personalInfoValidation } from './Helper';
import { ADD_INVITATION_REQUEST } from 'pages/Profile/components/Broker/components/BrokerGraphQL';
import { deleteDocandProfileImg } from 'utils/helper';
import NewDataPersonalInfoForm from './NewDataPersonalInfoForm';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';

const addressKeys = [
  'streetAddress1',
  'city1',
  'province1',
  'country1',
  'postalCode1',
  'isSameAddress'
];
const addressKeys2 = [
  'streetAddress2',
  'unitNumber2',
  'country2',
  'province2',
  'city2',
  'postalCode2'
];
const licenseKeys = ['licensedFsra', 'licenseNumber'];
const additionalInfoKeys = ['maritalStatus', 'occupation'];
const identyKeys = [
  'identificationType',
  'identificationNumber',
  'identificationDocs',
  'identificationBackDocs'
];
const secondIdentyKeys = [
  'secondaryIdentificationType',
  'secondaryIdentificationNumber',
  'secondaryIdentificationDocs',
  'secondaryIdentificationBackDocs'
];

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_VALIDATION':
      return { ...state, validation: { ...payload } };
    case 'UPDATE_INIT_VALUES':
      return {
        ...state,
        initValues: {
          ...payload,
          mortgageAdmin: `${payload.mortgageAdminId}`
        }
      };
    default:
      return { ...state };
  }
};

const PersonalInfo = () => {
  const [state, dispatch] = useReducer(reducer, {
    validation: personalInfoValidation,
    initValues: personalInfoValues
  });
  //Getting params of approve profile
  const { id } = useParams();
  const { state: params, pathname } = useLocation();
  const { userPersonalDetails } = useOutletContext();
  const { data: rolesList } = useContext(RoleContext);
  const user = getUser();
  const navigate = useNavigate();
  const [sendInvite] = useMutation(ADD_INVITATION_REQUEST);
  const [addPersonalInfo] = useMutation(ADD_PERSONAL_INFO);
  const { data: approvalPersonalInfoData } = useQuery(FETCH_APPROVING_PERSONAL_INFO, {
    variables: {
      userId: parseInt(id || user.id)
    }
  });
  const [approveSection] = useMutation(APPROVE_USER_BY_ADMIN);

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: PERSONAL_INFO_KEY
    }
  });

  const newFormData = data?.getSectionUpdatedFields?.updatedFields || {};

  const isCompleteProfile = pathname.includes('complete-profile');
  const isProfile = pathname.includes('profile');
  const isPathApproval = pathname.includes('approval');

  useEffect(() => {
    if (approvalPersonalInfoData && userPersonalDetails?.isPersonalInfo) {
      dispatch({
        type: 'UPDATE_INIT_VALUES',
        payload: approvalPersonalInfoData.getUserPersonalDetailById
      });
    } else if (userPersonalDetails?.workedWithMA && !userPersonalDetails?.isPersonalInfo) {
      dispatch({
        type: 'UPDATE_INIT_VALUES',
        payload: {
          ...state.initValues,
          workedWithMA: userPersonalDetails.workedWithMA,
          mortgageAdminId: userPersonalDetails.mortgageAdminId
        }
      });
    }
    if (!isCompleteProfile && isProfile) {
      dispatch({
        type: 'UPDATE_VALIDATION',
        payload: { ...personalInfoCommonValidation, ...personalInfoValidation }
      });
    }
  }, [approvalPersonalInfoData, userPersonalDetails]);

  const { validation, initValues } = state;

  const onRequestToMA = async (id) => {
    if (!id) return null;
    try {
      await sendInvite({
        variables: {
          invitedTo: id,
          invitedBy: user.id,
          roleId: findRoleIdByKey(MA_ROLE_KEY),
          inviteRequestType: REQUEST_TYPE
        }
      });
    } catch (err) {
      toast.error(err);
    }
  };

  const onSubmit = async (data) => {
    if (isPathApproval) return updateApprovePersonalInfo(data);

    try {
      let formObj = { ...data };
      let mortgageAdminId = formObj.workedWithMA ? Number(formObj.mortgageAdminId) : null;
      formObj.mortgageAdmin = null;
      if (userPersonalDetails.isPersonalInfo) {
        formObj = deleteDocandProfileImg(formObj, initValues);
      }
      const castValues = Yup.object({ ...validation }).cast(formObj);
      if (mortgageAdminId !== initValues.mortgageAdminId) {
        await onRequestToMA(mortgageAdminId);
      }

      const allValues = getUpdateValuesFromObj(userPersonalDetails, castValues);
      if (isProfile && !isCompleteProfile) {
        delete allValues.mortgageAdminId;
        delete allValues.approveSections;
        allValues.isUpdating = true;
        allValues.formSection = PERSONAL_INFO_KEY;
      } else {
        allValues.mortgageAdminId = mortgageAdminId;
      }

      const { data: result } = await addPersonalInfo({
        variables: { updatePersonalDetailsId: user.id, ...allValues }
      });
      toast.success(result?.updatePersonalDetails.message);
      navigate(
        pathname.includes('complete-profile') ? '/complete-profile/accounts' : '/profile/accounts'
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const findRoleIdByKey = (key) => {
    const filterParamKey = filter(rolesList?.rolesList.roles, (item) => item.roleKey === key);
    if (filterParamKey.length) {
      return filterParamKey[0].id;
    }
  };

  //On Profile Approve
  const updateApprovePersonalInfo = async (data) => {
    const approveValue = data.approveValue === 'true' ? true : false;
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updateInvestorApproveSection(
            userPersonalDetails.approveSections,
            PERSONAL_INFO_KEY,
            approveValue
          ),
          status: approveValue ? 'approved' : 'rejected',
          formSection: PERSONAL_INFO_KEY
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      navigate(`/approval/investor/${id}/accounts`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateValidationFromLicense = (flag) => {
    dispatch({
      type: 'UPDATE_VALIDATION',
      payload: flag
        ? { ...validation, ...licenseValidation }
        : !isCompleteProfile && isProfile
        ? { ...personalInfoCommonValidation, ...personalInfoValidation }
        : { ...personalInfoValidation }
    });
  };

  const renderSubmitButton = (isSubmitting) => {
    return !params?.profile && isSubmitting ? (
      <Loader size="lg" />
    ) : (
      <Button type="submit" color="primary" size="lg">
        {isCompleteProfile ? 'Save and continue' : 'Update'}
      </Button>
    );
  };

  const renderFormActionButtons = (isSubmitting) => {
    if (
      isPathApproval &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <div className="mt-4 text-end">
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2"
            disabled={isSubmitting}>
            Reject
          </Button>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button
              color="primary"
              {...{
                type: 'submit',
                name: 'approveValue',
                value: true
              }}>
              Approve Section
            </Button>
          )}
        </div>
      );
    }
    if (pathname.includes('profile')) {
      return <div className="mt-4 text-end">{renderSubmitButton(isSubmitting)}</div>;
    }
  };

  const renderPersonalInfo = () => {
    const userProfile = !pathname.includes('complete-profile') && pathname.includes('profile');
    return isPathApproval || pathname.includes('user') || userProfile ? (
      <ApprovePersonalInfo isForm userPersonalDetails={userPersonalDetails} />
    ) : null;
  };

  const checkFirstAddress = isPathApproval && checkIfFormValueExistInData(newFormData, addressKeys);
  const checkSecondAddress =
    isPathApproval && checkIfFormValueExistInData(newFormData, addressKeys2);
  const checkLicenseKey = isPathApproval && checkIfFormValueExistInData(newFormData, licenseKeys);
  const checkAdditionalInfoKeys =
    isPathApproval && checkIfFormValueExistInData(newFormData, additionalInfoKeys);
  const checkIdentyKeys = isPathApproval && checkIfFormValueExistInData(newFormData, identyKeys);
  const checkSecondIdentyKeys =
    isPathApproval && checkIfFormValueExistInData(newFormData, secondIdentyKeys);
  const checkPersonalInfoKeys =
    isPathApproval && checkIfFormValueExistInData(newFormData, personalInfoKeys);

  const renderUpdatedFieldForm = () => {
    if (
      isPathApproval &&
      userPersonalDetails.isUnderReviewed &&
      userPersonalDetails.approveRequiredSections[PERSONAL_INFO_KEY]
    ) {
      return (
        <Col>
          <NewDataPersonalInfoForm
            userPersonalDetails={userPersonalDetails}
            newFormData={newFormData}
            checkFirstAddress={checkFirstAddress}
            checkSecondAddress={checkSecondAddress}
            checkLicenseKey={checkLicenseKey}
            checkAdditionalInfoKeys={checkAdditionalInfoKeys}
            checkIdentyKeys={checkIdentyKeys}
            checkSecondIdentyKeys={checkSecondIdentyKeys}
            checkPersonalInfoKeys={checkPersonalInfoKeys}
          />
        </Col>
      );
    }
    return null;
  };

  return (
    <div>
      <Row>
        <Col>
          <Formik
            initialValues={initValues}
            validationSchema={
              isPathApproval ? Yup.object().shape({}) : Yup.object().shape({ ...validation })
            }
            onSubmit={onSubmit}
            enableReinitialize={true}
            validateOnMount={true}>
            {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
              <form
                onSubmit={(e) => {
                  const submitter = e.nativeEvent?.submitter;
                  if (submitter?.name && submitter?.value && isPathApproval) {
                    setFieldValue(submitter.name, submitter.value);
                  }
                  handleSubmit(e);
                }}>
                <ErrorScroll />
                {renderPersonalInfo()}
                <Address isOldData={checkFirstAddress} />
                {typeof values.isSameAddress === 'boolean' && !values.isSameAddress && (
                  <PreviousAddress isOldData={checkSecondAddress} />
                )}
                <Licensing
                  isOldData={checkLicenseKey}
                  updateValidationFromLicense={updateValidationFromLicense}
                />
                <AdditionalInfo isOldData={checkAdditionalInfoKeys} />
                <Identification
                  title="Identification"
                  formKey={{
                    forntDoc: 'identificationDocs',
                    backDoc: 'identificationBackDocs',
                    identyType: 'identificationType',
                    identyNum: 'identificationNumber'
                  }}
                  identyTypesKey={{
                    primary: 'identificationType',
                    secondary: 'secondaryIdentificationType'
                  }}
                  isOldData={checkIdentyKeys}
                />
                <Identification
                  title="Secondary Identification"
                  formKey={{
                    forntDoc: 'secondaryIdentificationDocs',
                    backDoc: 'secondaryIdentificationBackDocs',
                    identyType: 'secondaryIdentificationType',
                    identyNum: 'secondaryIdentificationNumber'
                  }}
                  secondary
                  identyTypesKey={{
                    primary: 'identificationType',
                    secondary: 'secondaryIdentificationType'
                  }}
                  isOldData={checkSecondIdentyKeys}
                />
                <ProfilePicture optional userPersonalDetails={userPersonalDetails} />
                {renderFormActionButtons(isSubmitting)}
              </form>
            )}
          </Formik>
        </Col>
        {renderUpdatedFieldForm()}
      </Row>
    </div>
  );
};

export default PersonalInfo;
