import { BROKER_ROLE_KEY, INVESTOR_ROLE_KEY, MA_ROLE_KEY, PB_ROLE_KEY } from 'constant';

const getFirstPathRole = (key, isUser) => {
  switch (key) {
    case INVESTOR_ROLE_KEY:
      return 'personal-info';
    case MA_ROLE_KEY:
      return 'personal-information';
    case BROKER_ROLE_KEY:
      return isUser ? 'personal-details' : 'personal-information';
    case PB_ROLE_KEY:
      return isUser ? 'personal-details' : 'personal-details';
    default:
      break;
  }
};

const WITH_MA = 'with_ma';
const WITHOUT_MA = 'without_ma';

const initFilters = {
  limit: 10,
  offset: 0,
  search: '',
  sortValue: '',
  sortKey: '',
  filter: { mas: [], pbs: [], withMAType: null }
};

export { getFirstPathRole, WITH_MA, WITHOUT_MA, initFilters };
