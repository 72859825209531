import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { accountTypes } from 'pages/Profile/common';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import ErrorBoundary from 'components/ErrorBoundary';
import CombineErrorMessage from 'components/CombineError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const AccountTypes = ({ children, isNewData, isOldData }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched } = useFormikContext();

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.accountName && touched.accountName) ||
      (errors.isAccreditedInvestor && touched.isAccreditedInvestor)
    );
  };

  return (
    <ErrorBoundary>
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0 position-relative">
            <h3 className="card-heading">Account Type</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          {children}

          {values.accountType && (
            <div>
              <Row className="input-spacing">
                <Col md={6} lg={6}>
                  <Field
                    size="lg"
                    id="accountName"
                    name="accountName"
                    type="text"
                    component={FormikInputField}
                    label={
                      values.accountType === 'trust'
                        ? 'Name this Account'
                        : 'Preferred Account Name'
                    }
                    isError={false}
                  />
                </Col>
              </Row>
              <div className="input-spacing">
                <Label>Are you a designed/accredited investor?</Label>
                <div className="d-flex">
                  {answer.map(({ label, value }, index) => {
                    return (
                      <div key={label} className={index === 1 ? 'ms-3' : ''}>
                        <Field
                          name="isAccreditedInvestor"
                          type="radio"
                          label={label}
                          value={value}
                          checked={
                            index === 1 ? !values.isAccreditedInvestor : values.isAccreditedInvestor
                          }
                          component={FormikRadioField}
                          isError={false}
                        />
                      </div>
                    );
                  })}
                </div>
                <p className="text-muted mt-2">
                  See Section 2 of{' '}
                  <a
                    href="https://www.ontario.ca/laws/regulation/080188/v11#BK2"
                    target="_blank"
                    className="text-primary">
                    Mortgage Brokerages, Lenders and Administrators Act
                  </a>
                </p>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </ErrorBoundary>
  );
};

export default AccountTypes;
