import React from 'react';
import { APPROVED_KEY, NOT_REQUESTED, PENDING_KEY, REJECTED_KEY } from 'constant';
import ProfilePendingCard from './components/ProfilePendingCard';
import SelectionOfMA from './components/SelectionOfMA';
import MaRequestPendingCard from './components/MaRequestingPendingCard';
import RejectProfileCard from './components/RejectProfileCard';
import MaRejectionCard from './components/MaRejectionCard';
import MAList from './MAList';

const SubmittingFlow = ({ userPersonalDetails, refetch }) => {
  const copyUser = { ...userPersonalDetails };
  const { approvedStatus, licensedFsra, requestStatus } = copyUser;
  if (approvedStatus === PENDING_KEY) {
    return (
      <ProfilePendingCard userPersonalDetails={userPersonalDetails}>
        <MAList refetch={refetch} />
      </ProfilePendingCard>
    );
  }
  if (approvedStatus === APPROVED_KEY && !licensedFsra && requestStatus === NOT_REQUESTED) {
    return (
      <SelectionOfMA userPersonalDetails={userPersonalDetails}>
        <MAList refetch={refetch} />
      </SelectionOfMA>
    );
  }
  if (approvedStatus === APPROVED_KEY && !licensedFsra && requestStatus === PENDING_KEY) {
    return <MaRequestPendingCard userPersonalDetails={userPersonalDetails} />;
  }
  if (approvedStatus === APPROVED_KEY && requestStatus === REJECTED_KEY) {
    return (
      <MaRejectionCard userPersonalDetails={userPersonalDetails}>
        <MAList refetch={refetch} />
      </MaRejectionCard>
    );
  }
  if (approvedStatus === REJECTED_KEY) {
    return <RejectProfileCard userPersonalDetails={userPersonalDetails} />;
  }
  return 'ABC';
};

export default SubmittingFlow;
