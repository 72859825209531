import React, { useReducer, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Contents from '../Contents';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
  Accordion,
  Button
} from 'reactstrap';
import {
  ACCOUNT_KEY,
  APPROVED_KEY,
  INVESTOR_KEY,
  PENDING_KEY,
  PERSONAL_INFO_KEY,
  REJECTED_KEY
} from 'constant';
import OnboardCard from '../Contents/OnboardCard';
import { getVerifiedBadge, getUnderReviewBadge } from 'pages/Profile/Helper';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';
import { getAccordionHeading } from './Helper';
import SubmittingFlow from './components/SubmittingFlow';
import Submission from 'pages/Profile/common/Submission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ProfilePendingCard from './components/SubmittingFlow/components/ProfilePendingCard';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CODE':
      return { ...state, code: action.payload };
    case 'UPDATE_COMPLETE':
      return { ...state, completed: action.payload, code: 0 };
    default:
      return state;
  }
};

const InvestorProfile = ({ userPersonalDetails, onRefetch, investorKey, setInvestorKey }) => {
  const { isUnderReviewed, approveRequiredSections, approvedStatus } = userPersonalDetails;

  const [state, dispatch] = useReducer(reducer, {
    code: 0,
    completed: false
  });
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });

  const [isAccrodianOpen, setAccrodianOpen] = useState(isTab ? '1' : null);
  const [isAccountUIShow, setIsAccountUIShow] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const accrodianToggle = (id) => {
    if (isAccrodianOpen === id) {
      setAccrodianOpen();
    } else {
      setAccrodianOpen(id);
    }
  };

  useEffect(() => {
    const flag =
      userPersonalDetails.approvedStatus === PENDING_KEY ||
      userPersonalDetails.approvedStatus === APPROVED_KEY;

    if (userPersonalDetails?.isEsign && pathname === '/dashboard' && flag) {
      dispatch({ type: 'UPDATE_COMPLETE', payload: true });
    }
    setIsAccountUIShow(false);
  }, [investorKey]);

  const { completed } = state;

  const checkAcountUI = (formData) => {
    const { isOld, isApproved } = formData;
    if ((isOld && isApproved) || (!isOld && !isApproved)) {
      setIsAccountUIShow(false);
    } else {
      setIsAccountUIShow(true);
    }
  };

  const renderContents = () => {
    return (
      <Outlet
        context={{
          userPersonalDetails,
          profileKey: INVESTOR_KEY,
          checkAcountUI: checkAcountUI,
          setInvestorKey: setInvestorKey,
          investorKey: investorKey
        }}
      />
    );
  };

  const checkProfileStatus = () => {
    if (approvedStatus === APPROVED_KEY && !isUnderReviewed) {
      return getVerifiedBadge();
    } else if (approvedStatus === APPROVED_KEY && isUnderReviewed) {
      return getUnderReviewBadge();
    }
  };

  const getHeading = () => {
    if (pathname === '/dashboard') {
      return <h2>Hey {userPersonalDetails?.preferredName}!</h2>;
    }
    if (pathname.includes('complete-profile')) {
      return <h2>Complete your Profile</h2>;
    }
    if (pathname.includes('approval'))
      return (
        <div className="d-flex align-items-center">
          <h2>Approve Investor</h2>
          {userPersonalDetails.approvedStatus === REJECTED_KEY && (
            <h5 className="ms-2 mb-0 font-size-14 mt-2" style={{ color: '#EA6666' }}>
              REJECTED
            </h5>
          )}
        </div>
      );
    if (pathname.includes('profile') || pathname.includes('user'))
      return (
        <div className="d-flex align-items-center">
          <h2>Profile</h2>
          {checkProfileStatus()}
        </div>
      );
  };

  const renderOnBoardRejectCard = () => {
    if (userPersonalDetails?.approvedStatus === REJECTED_KEY && pathname === '/dashboard') {
      return <Submission userDetails={userPersonalDetails} onlyCard />;
    }
    if (pathname === '/dashboard' && userPersonalDetails?.approvedStatus === PENDING_KEY) {
      return <OnboardCard userPersonalDetails={userPersonalDetails} />;
    }
    if (
      pathname === '/dashboard' &&
      userPersonalDetails?.approvedStatus === PENDING_KEY &&
      userPersonalDetails.isEsign
    ) {
      return <ProfilePendingCard userPersonalDetails={userPersonalDetails} />;
    }
  };

  const onHandleBack = () => {
    if (!userPersonalDetails.isEsign) {
      return navigate(`/users/onboarding`);
    } else if (userPersonalDetails.approvedStatus === REJECTED_KEY) {
      return navigate(`/approvals/rejected`);
    } else {
      navigate(`/${pathname.includes('approval') ? 'approvals' : 'users'}/investor`);
    }
  };

  const renderBackButton = () => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return (
        <Button color="link" className="p-0 font-size-14" onClick={onHandleBack}>
          <FontAwesomeIcon icon={faArrowLeft} className="font-size-14 me-2" />
          Back to {pathname.includes('approval') ? 'approvals' : 'users'}
        </Button>
      );
    }
    return null;
  };

  const getSectionApprovalStatus = () => {
    let sectionName = null;

    if (pathname.includes('personal-info')) {
      sectionName = PERSONAL_INFO_KEY;
    } else if (pathname.includes('accounts')) {
      sectionName = ACCOUNT_KEY;
    }

    if (isUnderReviewed && pathname.includes('approval') && approveRequiredSections[sectionName]) {
      if (pathname.includes('accounts')) {
        return isAccountUIShow;
      } else {
        return true;
      }
    }
    return false;
  };

  const isApproval = getSectionApprovalStatus();

  return (
    <div>
      {completed ? (
        <SubmittingFlow userPersonalDetails={userPersonalDetails} refetch={onRefetch} />
      ) : (
        <div className={`${isApproval ? 'container-fluid' : 'container'}`}>
          <Row className="header-sticky">
            {!isApproval ? <Col md={1} lg={1} /> : null}
            <Col className="content-heading">
              {renderBackButton()}
              {getHeading()}
            </Col>
          </Row>
          <Row>
            {/* {isApproval ? <Col md={1} lg={1} /> : null} */}
            <Col md={1} lg={1} />
            {!isTab ? (
              <>
                {!completed && (
                  <Col md={isApproval ? 2 : 3} lg={isApproval ? 2 : 3}>
                    <Contents
                      investorKey={investorKey}
                      setInvestorKey={setInvestorKey}
                      userPersonalDetails={userPersonalDetails}
                    />
                  </Col>
                )}
              </>
            ) : null}

            <Col md={isTab ? 10 : !isApproval ? 7 : ''} lg={isTab ? 10 : !isApproval ? 7 : ''}>
              {isTab ? (
                <Accordion
                  open={isAccrodianOpen}
                  toggle={accrodianToggle}
                  className="content-accordion"
                  defaultOpen="1">
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <h4 className={`mb-0 ${!isAccrodianOpen ? 'text-primary' : ''}`}>
                        {isAccrodianOpen ? 'Contents' : getAccordionHeading(pathname)}
                      </h4>
                    </AccordionHeader>
                    <AccordionBody accordionId="1" className="p-0">
                      {!completed && (
                        <Contents
                          investorKey={investorKey}
                          userPersonalDetails={userPersonalDetails}
                          setInvestorKey={setInvestorKey}
                          isTab
                        />
                      )}
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              ) : null}
              <div className="profile-height" ref={ref}>
                {renderContents()}
                {renderOnBoardRejectCard()}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default InvestorProfile;
