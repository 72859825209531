import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { formatContactNumber } from 'utils';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { MA_ROLE_KEY, NOT_REQUESTED, PENDING_KEY, REQUEST_TYPE } from 'constant';
import { getUser } from 'utils/UserDetails';
import { ADD_INVITATION_REQUEST } from 'pages/Profile/components/Broker/components/BrokerGraphQL';
import { filter } from 'lodash';
import { RoleContext } from 'context/Role';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { GET_RECOMMENDED_INVESTOR } from 'pages/FindMortgageAdmin/GraphQL';
import { getRandomColor } from 'utils/helper';
import Avatar from 'components/Avatar';

const MAList = ({ refetch, limit = 2, isDashboard }) => {
  const { data: rolesList } = useContext(RoleContext);
  const isMob = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  const [sendInvite, { loading: addMALoading }] = useMutation(ADD_INVITATION_REQUEST);
  const {
    data,
    loading,
    refetch: refetchMA
  } = useQuery(GET_RECOMMENDED_INVESTOR, {
    variables: {
      offset: 0,
      limit: limit
    }
  });
  const user = getUser();
  const navigate = useNavigate();

  if (loading) {
    return <PageLoader />;
  }

  const mortgages = data?.getRecommendedMAsForInvestor?.maData
    ? data?.getRecommendedMAsForInvestor?.maData
    : [];

  const findRoleIdByKey = (key) => {
    const filterParamKey = filter(rolesList?.rolesList.roles, (item) => item.roleKey === key);
    if (filterParamKey.length) {
      return filterParamKey[0].id;
    }
  };

  const onRequestMA = async (id) => {
    try {
      await sendInvite({
        variables: {
          invitedTo: id,
          invitedBy: user.id,
          roleId: findRoleIdByKey(MA_ROLE_KEY),
          inviteRequestType: REQUEST_TYPE
        }
      });
      refetchMA();
      refetch();
    } catch (err) {
      refetch();
      toast.error(err.message);
    }
  };

  const getHeadingTitle = (mortgage) => {
    if (mortgage?.operatingName) return mortgage?.operatingName;
    if (mortgage?.businessName) return mortgage?.businessName;
    return `${mortgage?.firstName} ${mortgage?.lastName}`;
  };

  const renderMortgageAdmin = () => {
    return mortgages.map((mortgage) => (
      <div
        className={`${
          isMob ? 'd-block text-center' : 'd-flex align-items-center'
        } p-4 align-items-center justify-content-between d-card ${!isDashboard ? 'mb-4' : ''}`}
        style={{ alignItems: 'center' }}
        key={mortgage.id}>
        <div
          className="d-flex align-items-center"
          style={{ flex: isMob ? '0 0 100%' : '0 0 150px' }}>
          <Avatar
            size={isMob ? 260 : 150}
            image={mortgage?.logo || mortgage?.profilePicture}
            fname={mortgage?.firstName}
            lname={mortgage?.lastName}
            borderradius="0"
            fontsize={80}
            className={isMob ? 'w-100' : ''}
            backgroundcolour={getRandomColor()}
            textcolour="#fff"
          />
        </div>
        <div className={`text-left ${isMob ? 'mb-3' : 'ms-3'}`} style={{ flex: 1 }}>
          <p
            className="mb-1 font-size-15"
            style={{ maxWidth: '250px', fontWeight: 'bold' }}
            title={getHeadingTitle(mortgage)}>
            {getHeadingTitle(mortgage)}
          </p>
          <p className="mb-1 font-size-15 semi-bold">{mortgage?.businessContacteMail || '-'}</p>
          <p className="mb-2 font-size-15 semi-bold">
            {mortgage?.businessContactPhoneNumber
              ? formatContactNumber(mortgage.businessContactPhoneNumber)
              : '-'}
          </p>
        </div>
        <div
          className={`${isMob ? 'mt-2' : 'ms-4'}`}
          style={{ alignSelf: isMob ? 'center' : 'flex-start' }}>
          {mortgage?.requestStatus === NOT_REQUESTED ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => onRequestMA(mortgage.id)}
              disabled={addMALoading}>
              Add as my MA
            </Button>
          ) : (
            <Button color="link" className="pe-none text-capitalize">
              Requested
            </Button>
          )}
        </div>
      </div>
    ));
  };

  const onHandleRoute = () => {
    navigate('/find-mortgage-admin');
  };

  return (
    <div>
      {renderMortgageAdmin()}
      <div className={`d-${isDashboard ? 'none' : 'flex'} justify-content-end`}>
        <Button color="link" className="px-0" onClick={onHandleRoute}>
          See more
        </Button>
      </div>
    </div>
  );
};

export default MAList;
