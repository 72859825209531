import { gql } from '@apollo/client';

const ADD_PERSONAL_INFO = gql`
  mutation UpdatePersonalDetails(
    $isSameAddress: Boolean
    $profilePicture: String
    $secondaryIdentificationBackDocs: String
    $secondaryIdentificationDocs: String
    $secondaryIdentificationNumber: String
    $secondaryIdentificationType: String
    $identificationBackDocs: String
    $identificationDocs: String
    $identificationNumber: String
    $identificationType: String
    $mortgageAdmin: String
    $mortgageAdminId: Int
    $workedWithMA: Boolean
    $licensedFsra: Boolean
    $licenseNumber: String
    $accountType: String
    $occupation: String
    $maritalStatus: String
    $postalCode2: String
    $country2: String
    $province2: String
    $city2: String
    $unitNumber2: String
    $streetAddress2: String
    $postalCode1: String
    $country1: String
    $updatePersonalDetailsId: Int
    $firstName: String
    $lastName: String
    $preferredName: String
    $streetAddress1: String
    $unitNumber1: String
    $city1: String
    $province1: String
    $userNote: String
    $customUrl: String
    $isUpdating: Boolean
    $formSection: String
  ) {
    updatePersonalDetails(
      isSameAddress: $isSameAddress
      profilePicture: $profilePicture
      secondaryIdentificationBackDocs: $secondaryIdentificationBackDocs
      secondaryIdentificationDocs: $secondaryIdentificationDocs
      secondaryIdentificationNumber: $secondaryIdentificationNumber
      secondaryIdentificationType: $secondaryIdentificationType
      identificationBackDocs: $identificationBackDocs
      identificationDocs: $identificationDocs
      identificationNumber: $identificationNumber
      identificationType: $identificationType
      mortgageAdmin: $mortgageAdmin
      mortgageAdminId: $mortgageAdminId
      workedWithMA: $workedWithMA
      licensedFsra: $licensedFsra
      licenseNumber: $licenseNumber
      accountType: $accountType
      occupation: $occupation
      maritalStatus: $maritalStatus
      postalCode2: $postalCode2
      country2: $country2
      province2: $province2
      city2: $city2
      unitNumber2: $unitNumber2
      streetAddress2: $streetAddress2
      postalCode1: $postalCode1
      country1: $country1
      id: $updatePersonalDetailsId
      firstName: $firstName
      lastName: $lastName
      preferredName: $preferredName
      streetAddress1: $streetAddress1
      unitNumber1: $unitNumber1
      city1: $city1
      province1: $province1
      userNote: $userNote
      customUrl: $customUrl
      isUpdating: $isUpdating
      formSection: $formSection
    )
  }
`;

const FETCH_APPROVING_PERSONAL_INFO = gql`
  query GetUserPersonalDetailById($userId: Int!) {
    getUserPersonalDetailById(userId: $userId) {
      id
      email
      roleId
      firstName
      lastName
      preferredName
      countryCode
      phoneNumber
      sign
      isVerified
      customUrl
      invitedBy
      streetAddress1
      unitNumber1
      city1
      province1
      country1
      postalCode1
      streetAddress2
      unitNumber2
      city2
      province2
      country2
      postalCode2
      maritalStatus
      occupation
      accountType
      licenseNumber
      licensedFsra
      workedWithMA
      identificationType
      identificationNumber
      identificationDocs
      identificationBackDocs
      secondaryIdentificationType
      secondaryIdentificationNumber
      secondaryIdentificationDocs
      secondaryIdentificationBackDocs
      profilePicture
      approveSections
      createdAt
      updatedAt
      isSameAddress
      mortgageAdmin
      mortgageAdminId
      userNote
      requestStatus
      finalApprovalComment
      enableNewsOffers
    }
  }
`;

export { ADD_PERSONAL_INFO, FETCH_APPROVING_PERSONAL_INFO };
