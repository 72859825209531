import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import CombineErrorMessage from 'components/CombineError';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';

const BusinessType = ({
  isOldData,
  isNewData,
  title = 'Business Type',
  onUpdateValidation = () => ''
}) => {
  const [hasError, setHasError] = useState(false);
  const { touched, errors } = useFormikContext();
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });

  useEffect(() => {
    const err = checkError();
    setHasError(err);
  }, [touched, errors]);

  const checkError = () => {
    return touched.bussinessType && errors.bussinessType;
  };

  const types = [
    { id: 'partnership', label: 'Partnership' },
    { id: 'corporation', label: 'Corporation' },
    { id: 'sole_proprietor', label: 'Sole Proprietor' }
  ];

  return (
    <div>
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0 position-relative">
            <h3 className="card-heading">{title}</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          <div>
            <Label>What is the type of this business?</Label>
            <Row className={`${isMobile ? 'w-100' : 'w-50'} input-spacing`}>
              {types.map((item, index) => (
                <Col md={6} lg={6} key={item.id}>
                  <Field
                    size="lg"
                    key={item}
                    id={item.id}
                    name="bussinessType"
                    label={item.label}
                    onClick={onUpdateValidation}
                    component={FormikRadioboxField}
                    isError={false}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BusinessType;
