import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import MAInvitations from './components/MAInvitations';
import MaOfInvestors from './components/MaOfInvestors';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import {
  ACCEPTED_KEY,
  INVESTOR_KEY,
  MORTGAGE_ADMIN_KEY,
  PENDING_KEY,
  REJECTED_KEY
} from 'constant';
import { getApproveObj } from 'pages/Profile/Helper';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { getUser } from 'utils/UserDetails';
import { GET_RECOMMENDED_INVESTOR } from 'pages/FindMortgageAdmin/GraphQL';

const MortgageAdminCard = ({ title = 'Mortgage Admin' }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [approveSection] = useMutation(APPROVE_USER_BY_ADMIN);
  const user = getUser();
  const { userPersonalDetails } = useOutletContext();

  const { data: result, refetch } = useQuery(GET_RECOMMENDED_INVESTOR, {
    variables: {
      userId: userPersonalDetails?.id ? Number(userPersonalDetails?.id) : user.id,
      requestStatus: [PENDING_KEY, ACCEPTED_KEY]
    }
  });
  const connectRequestedMa = result?.getRecommendedMAsForInvestor?.maData
    ? result?.getRecommendedMAsForInvestor?.maData
    : [];

  const onApprove = async (approveValue) => {
    let approveSectionObj = getApproveObj(
      userPersonalDetails.approveSections,
      INVESTOR_KEY,
      MORTGAGE_ADMIN_KEY,
      approveValue
    );
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: approveSectionObj,
          status: approveValue ? 'approved' : 'rejected',
          formSection: MORTGAGE_ADMIN_KEY
        }
      });
      navigate(`/approval/investor/${id}/e-sign`);
      toast.success(result.approveUserBySuperAdmin.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderAddBtn = () => {
    if (!pathname.includes('complete-profile') && pathname.includes('profile')) {
      return (
        <Link to="/find-mortgage-admin">
          <Button color="link" className="px-0">
            Add +
          </Button>
        </Link>
      );
    }
    return null;
  };

  const renderInvitationList = () => {
    if (!pathname.includes('complete-profile') && pathname.includes('profile')) {
      return <MAInvitations fetchMa={refetch} />;
    }
    return null;
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h3 className="">{title}</h3>
            {renderAddBtn()}
          </div>
          {renderInvitationList()}
          <MaOfInvestors maList={connectRequestedMa} />
        </CardBody>
      </Card>
      {pathname.includes('approval') &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY) ? (
        <div className="mt-3 d-flex justify-content-end">
          <Button color="danger" className="ms-3" onClick={() => onApprove(false)}>
            Reject
          </Button>

          <Button color="primary" className="ms-3" onClick={() => onApprove(true)}>
            Approve
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default MortgageAdminCard;
