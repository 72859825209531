import { ACCOUNT_KEY } from 'constant';
import { getUpdateValuesFromObj } from 'pages/Profile/Helper';
import { toast } from 'react-toastify';

const updateAccountDetails = async (data, updateAPI, initValues, pathname, user) => {
  try {
    const formObj = { ...data };
    delete formObj.id;
    delete formObj.accountType;
    delete formObj.confirmAndSign;
    delete formObj.member_id;
    delete formObj.trust_member;
    delete formObj.corporate_member;
    delete formObj.joint_member;
    delete formObj.beneficiary_members;
    delete formObj.directors_members;
    delete formObj.joint_members;
    delete formObj.trust_members;
    delete formObj.isApproved;

    const newData = getUpdateValuesFromObj(initValues, formObj);

    delete newData.beneficiary_members;
    delete newData.directors_members;
    delete newData.joint_members;
    delete newData.trust_members;

    let isUpdatingObj = {};
    if (pathname.includes('profile') && !pathname.includes('complete-profile')) {
      isUpdatingObj = { isUpdating: true };
    }

    if (data.accountType === 'individual') {
      const { data: result } = await updateAPI.individual({
        variables: {
          userId: user.id,
          investorAccountId: data.id,
          individualAccountInput: { ...newData },
          ...isUpdatingObj
        }
      });
      toast.success(result.updateIndividualAccount.message);
    }
    if (data.accountType === 'trust') {
      if (newData.accountNumber) {
        newData.accountNumber = `${newData.accountNumber}`;
      }
      const { data: result } = await updateAPI.trust({
        variables: {
          userId: user.id,
          investorAccountId: data.id,
          trustAccountInput: { ...newData },
          ...isUpdatingObj
        }
      });
      toast.success(result.updateTrustAccount.message);
    }
    if (data.accountType === 'joint') {
      const { data: result } = await updateAPI.joint({
        variables: {
          userId: user.id,
          investorAccountId: data.id,
          jointAccountInput: { ...newData },
          ...isUpdatingObj
        }
      });
      toast.success(result.updateJointAccount.message);
    }
    if (data.accountType === 'corporation') {
      const { data: result } = await updateAPI.corporation({
        variables: {
          userId: user.id,
          corporateMemberId: data.memberId,
          investorAccountId: data.id,
          corporateAccountInput: { ...newData },
          ...isUpdatingObj
        }
      });

      toast.success(result.updateCorporateAccountDetails.message);
    }
    updateAPI.refetchAccounts();
  } catch (error) {
    toast.error(error.message);
  }
};

const checkAllocationSumOfIncome = (values) => {
  const { employment, interest, capitalGains, otherIncome } = values;
  const employPercent = employment ? Number(employment) : 0;
  const interestPercent = interest ? Number(interest) : 0;
  const capitalGainsPercent = capitalGains ? Number(capitalGains) : 0;
  const otherIncomePercent = otherIncome ? Number(otherIncome) : 0;
  const total = employPercent + interestPercent + capitalGainsPercent + otherIncomePercent;
  return total === 100;
};

export { updateAccountDetails, checkAllocationSumOfIncome };
