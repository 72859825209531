import React, { useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import RemoveModal from '../RemoveModal';
import { useMutation } from '@apollo/client';
import {
  REMOVE_BENEFICARY,
  REMOVE_DIRECTOR,
  REMOVE_JOINT_MEMBER,
  REMOVE_TRUST_MEMBER
} from 'pages/Profile/ProfileGraphql';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { formatContactNumber } from 'utils';
import Avatar from 'components/Avatar';
import { useLocation } from 'react-router-dom';
import { getUpdatedMembers } from 'pages/Profile/Helper';
import { getUser } from 'utils/UserDetails';

const BenifiMembers = ({
  members,
  onEdit,
  onRemove,
  onAddMember,
  title,
  addButtonTitle,
  isJoint,
  isBenefi,
  isDirector,
  isTrust,
  refetch,
  formKey = {},
  isNewData,
  isNewDataForm,
  isOldData
}) => {
  const user = getUser();
  const [state, setState] = useState({
    modal: false,
    removeData: {}
  });
  const { pathname } = useLocation();
  const [removeBenifi, { loading: loadingBeneficiary }] = useMutation(REMOVE_BENEFICARY);
  const [remoeveDirector, { loading: loadingDirector }] = useMutation(REMOVE_DIRECTOR);
  const [removeTrustMember, { loading: loadingTrustMember }] = useMutation(REMOVE_TRUST_MEMBER);
  const [removeJointMember, { loading: loadingJointMember }] = useMutation(REMOVE_JOINT_MEMBER);
  const isMobile = useMediaQuery({
    query: '(max-width: 680px)'
  });

  const onToggle = async (data) => {
    setState((pre) => ({
      modal: !pre.modal,
      removeData: data || {}
    }));
  };

  const { removeData, modal } = state;
  const onRemoveBenefi = async () => {
    if (typeof removeData.id === 'number' && isDirector) {
      return onHandleRemoveDirector();
    }
    if (typeof removeData.id === 'number' && isBenefi) {
      return onHandleRemoveBenefi();
    }
    if (typeof removeData.id === 'number' && isTrust) {
      return onHandleRemoveTrust();
    }
    if (typeof removeData.id === 'number' && isJoint) {
      return onHandleRemoveJoint();
    }
    onRemove(removeData.id);
    onToggle();
  };

  const onHandleRemoveBenefi = async () => {
    try {
      const result = await removeBenifi({
        variables: {
          userId: user?.id,
          beneficiaryId: removeData.id
        }
      });
      refetch();
      onToggle();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onHandleRemoveDirector = async () => {
    try {
      const result = await remoeveDirector({
        variables: {
          userId: user?.id,
          directorId: removeData.id
        }
      });
      refetch();
      onToggle();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onHandleRemoveTrust = async () => {
    try {
      const result = await removeTrustMember({
        variables: {
          userId: user?.id,
          trustMemberId: removeData.id
        }
      });
      refetch();
      onToggle();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onHandleRemoveJoint = async () => {
    try {
      await removeJointMember({
        variables: {
          userId: user?.id,
          jointMemberId: removeData.id
        }
      });
      refetch();
      onToggle();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderAddBtn = () => {
    if (pathname.includes('complete-profile') || pathname.includes('profile')) {
      return (
        <div>
          <Button type="button" onClick={onAddMember} color="link" className="p-0">
            {addButtonTitle} <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      );
    }
  };

  const renderEditRemoveBtn = (item) => {
    if (pathname.includes('complete-profile') || pathname.includes('profile')) {
      return (
        <div>
          {!item.isSelf && (
            <Button color="link" onClick={() => onToggle(item)} className="p-0 font-size-14">
              <span className="text-danger"> Remove</span>
            </Button>
          )}
          <Button color="link" onClick={() => onEdit(item.id)} className="py-0 font-size-14">
            Edit
          </Button>
        </div>
      );
    }
  };

  const renderEditRemoveBtnMobile = (item) => {
    if (pathname.includes('complete-profile')) {
      return (
        <div>
          {!item.isSelf && (
            <Button color="link" onClick={() => onToggle(item)} className="p-0 font-size-14">
              <span className="text-danger"> Remove</span>
            </Button>
          )}
          <Button color="link" onClick={() => onEdit(item.id)} className="py-0 font-size-14">
            Edit
          </Button>
        </div>
      );
    }
  };

  const getRemoveNameTitle = () => {
    if (
      formKey?.firstName &&
      formKey?.lastName &&
      removeData[formKey?.firstName] &&
      removeData[formKey?.lastName]
    ) {
      return `${removeData[formKey?.firstName]} ${removeData[formKey?.lastName]}`;
    } else {
      return `${removeData?.beneficiaryLegalName} ${removeData?.beneficiaryLastName}`;
    }
  };

  const updatedMembers = getUpdatedMembers(members, isNewDataForm);

  return (
    <div>
      <RemoveModal
        isOpen={modal}
        toggle={() => onToggle()}
        onClick={onRemoveBenefi}
        name={getRemoveNameTitle()}
        loading={loadingBeneficiary || loadingDirector || loadingTrustMember || loadingJointMember}
      />
      <Card>
        <CardBody className="p-4">
          <div className="d-flex justify-content-between align-items-center p-0 position-relative">
            <h3 className="card-heading">{title}</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
            {renderAddBtn()}
          </div>
          {!members.length && isJoint && (
            <div>
              <h5>You must add at least one joint member.</h5>
            </div>
          )}
          {updatedMembers?.length
            ? updatedMembers.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="d-flex justify-content-between mb-3"
                    style={{ background: '#F8F8F8', padding: '35px' }}>
                    <div className={`${isMobile ? 'd-block w-100' : 'd-flex'} align-items-center`}>
                      <div
                        className={`${isMobile ? 'mb-2' : 'me-3'} d-flex justify-content-between`}>
                        <Avatar
                          fname={
                            formKey?.firstName && typeof item.id === 'number'
                              ? item[formKey?.firstName]
                              : item.beneficiaryLegalName
                          }
                          lname={
                            formKey?.lastName && typeof item.id === 'number'
                              ? item[formKey?.lastName]
                              : item.beneficiaryLastName
                          }
                          size={60}
                          fontsize="25"
                        />
                        {isMobile ? renderEditRemoveBtnMobile(item) : null}
                      </div>
                      <div>
                        <h5 className="font-size-16 mb-1">
                          <span className="font-size-18">{`${
                            formKey?.firstName && typeof item.id === 'number'
                              ? item[formKey?.firstName]
                              : item.beneficiaryLegalName
                          } ${
                            formKey?.lastName && typeof item.id === 'number'
                              ? item[formKey?.lastName]
                              : item.beneficiaryLastName
                          }`}</span>
                        </h5>
                        <p className="mb-0 text-muted">
                          {formKey?.phoneNumber && typeof item.id === 'number'
                            ? formatContactNumber(item[formKey?.phoneNumber])
                            : formatContactNumber(item.beneficiaryPhoneNo)}
                        </p>
                      </div>
                    </div>
                    {!isMobile ? (
                      <div>
                        {renderEditRemoveBtn(item)}
                        <div className="text-end me-3">
                          {!isBenefi ? (
                            <span className="text-muted font-size-14">
                              {formKey?.relation && typeof item.id === 'number'
                                ? item[formKey?.relation]
                                : item.beneficiaryRelationAccount}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : !isJoint && 'NA'}
        </CardBody>
      </Card>
    </div>
  );
};

export default BenifiMembers;
