import React from 'react';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import infoIcon from 'assets/icons/info.svg';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';
import MaRequestPendingCard from '../MaRequestingPendingCard';
import { APPROVED_KEY, PENDING_KEY } from 'constant';

const unLicensedText =
  'Investors that are not licensed through FSRA will need to invest through a Mortgage Administrator, Mortgage Investment Firm or Brokerage.';
const licensedText =
  'It is typically recommended to lend through a Mortgage Administrator, as they can help ease your operations by managing origination and servicing of your loan.';

const ProfilePendingCard = ({ userPersonalDetails, children }) => {
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });
  const isTabTwo = useMediaQuery({ query: '(max-width: 870px)' });
  const renderMARequestCard = () => {
    if (
      userPersonalDetails.requestStatus === PENDING_KEY &&
      userPersonalDetails.approvedStatus === APPROVED_KEY
    ) {
      return <MaRequestPendingCard userPersonalDetails={userPersonalDetails} isNotApprove />;
    }
    if (
      userPersonalDetails &&
      userPersonalDetails.licensedFsra &&
      !userPersonalDetails.workedWithMA
    ) {
      return (
        <Card>
          <CardBody>
            <h3>
              {userPersonalDetails.licensedFsra && userPersonalDetails.licenseNumber
                ? 'A mortgage administrator can help.'
                : 'A mortgage administrator is required.'}
            </h3>
            <p className="font-size-18 my-4 semi-bold">
              {userPersonalDetails.licensedFsra ? licensedText : unLicensedText}
            </p>
            <Label className="mb-3">Here are a few recommended Administrators:</Label>
            {children}
          </CardBody>
        </Card>
      );
    }
  };

  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {userPersonalDetails.preferredName}!</h2>
      <Row>
        <Col md={isTabTwo ? 12 : 4} lg={isTabTwo ? 12 : 4} className={isTabTwo ? 'mb-3' : ''}>
          <Card>
            <CardBody>
              <h3>Thank you for your submission.</h3>
              <p className="font-size-18 my-4 semi-bold">
                Your profile is being reviewed, we will contact you if we have any questions.
              </p>
              <p className="d-flex align-items-center font-size-18 my-4 semi-bold">
                <img
                  src={infoIcon}
                  className="me-2"
                  alt="info icon"
                  style={{ height: '20px', width: '20px' }}
                />
                Waiting on Openfund
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md={isTabTwo ? 12 : isTab ? 8 : 6} lg={isTabTwo ? 12 : isTab ? 8 : 6}>
          {renderMARequestCard()}
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePendingCard;
