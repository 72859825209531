import React, { createContext, useMemo, useState } from 'react';
import { initFilters } from '../../Helper';

export const UsersFilterContext = createContext('');

const UserFilterProvider = ({ children }) => {
  const [filters, setFilter] = useState(initFilters);

  const onChangeFilters = (newFilter, isInit) => {
    if (isInit) {
      return setFilter(initFilters);
    }
    setFilter((pre) => ({
      ...pre,
      ...newFilter
    }));
  };

  let context = useMemo(
    () => ({
      filters,
      onChangeFilters
    }),
    [filters]
  );

  return <UsersFilterContext.Provider value={context}>{children}</UsersFilterContext.Provider>;
};

export default UserFilterProvider;
