import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import infoIcon from 'assets/icons/info.svg';

const Information = ({ onClose, first }) => {
  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <Card className="mt-4">
          <CardBody className="p-4">
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <img src={infoIcon} alt="info icon" />
                  <span className="font-size-14 ps-2 text-muted">INFORMATION</span>
                </div>
                <Button color="link" className="p-0" type="button" onClick={onClose}>
                  <FontAwesomeIcon icon={faClose} className="font-size-18 text-dark" />
                </Button>
              </div>
            </div>
            <p className="font-size-14 black-varient-text mb-2">
              {first
                ? 'Investors that are not licensed through FSRA will need to invest through a Mortgage Administrator, Mortgage Investment Firm or Brokerage.'
                : 'It is typically recommended to lend through a Mortgage Administrator.'}
            </p>
            <p className="font-size-14 black-varient-text mt-2">
              Here are a few recommended Administrators
            </p>
            <div className="p-3 bg-light">
              <Row>
                <Col md={4} lg={4} sm={4}>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Wilson_logo.jpg/1200px-Wilson_logo.jpg"
                    alt="logo-img"
                    className="w-100 object-fit-cover"
                    height={80}
                  />
                </Col>
                <Col className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 font-size-14">contact@blsm.ca</p>
                    <p className="mb-0 font-size-14">+1(368)3686798</p>
                  </div>
                </Col>
              </Row>
            </div>
            {first && (
              <p className="font-size-14 black-varient-text mt-2 semi-bold">
                Please refer to your dashboard to find a suitable Administrator.
              </p>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Information;
