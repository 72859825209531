const getAccordionHeading = (pathname) => {
  if (pathname === '/dashboard') {
    return 'Complete your Profile';
  }
  if (pathname.includes('personal')) return 'Personal Information';
  if (pathname.includes('accounts')) return 'Accounts';
  if (pathname.includes('lending-criteria')) return 'Lending Criteria';
  if (pathname.includes('mortgage-admin')) return 'Mortgage Admin';
  if (pathname.includes('email')) return 'Email Confirmation';
  if (pathname.includes('final')) return 'Final Approval';
  if (pathname.includes('e-sign')) return 'Signature';
};

export { getAccordionHeading };
