import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CombineErrorMessage from 'components/CombineError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const PrincipalRepresentative = ({ isNewData, isOldData }) => {
  const [hasError, setHasError] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return errors.principlerepresentativePosition && touched.principlerepresentativePosition;
  };
  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0 position-relative">
            <h3 className="card-heading">Principal Representative</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          <Row>
            <Col sm="12" md="6" lg="6">
              <div className="input-spacing">
                <Field
                  size="lg"
                  name="principlerepresentativePosition"
                  label="Position of Principal Representative"
                  component={FormikInputField}
                  type="text"
                  isError={false}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default PrincipalRepresentative;
