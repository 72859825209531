import { gql } from '@apollo/client';

const BUSINESS_INFO = gql`
  mutation AddBusinessInfo($businessInfoInput: BusinessInfoInput!, $userId: Int!) {
    addBusinessInfo(businessInfoInput: $businessInfoInput, userId: $userId)
  }
`;

const UPDATE_BUSINESS_INFO = gql`
  mutation UpdateBusinessInfo($businessInfoInput: BusinessInfoInput!, $userId: Int!) {
    updateBusinessInfo(businessInfoInput: $businessInfoInput, userId: $userId)
  }
`;

export { BUSINESS_INFO, UPDATE_BUSINESS_INFO };
