import { validateNumber } from 'utils';
import * as Yup from 'yup';

const principalBrokerInitValue = {
  bussinessType: null,
  brokerageLicenseNumber: null,
  legalBusinessName: null,
  partnershipNumber: null,
  operatingName: null,
  jurisdiction: null,
  residency: null,
  isRegisteredBusinessName: null,
  logo: null,
  corporationNumber: null,
  streetAddress: null,
  unitNumber: null,
  city: null,
  province: null,
  country: null,
  postalCode: null,
  website: null,
  websitePrefix: '',
  businessContactEmail: null,
  businessContactNumberType: null,
  businessContactPhoneNumber: null
};

Yup.addMethod(Yup.string, 'validateNumber', validateNumber);

const principalBrokerValidation = {
  bussinessType: Yup.string().nullable().trim().required('Required'),
  brokerageLicenseNumber: Yup.string()
    .nullable()
    .trim()
    .required('License number required')
    .max(25, 'Enter valid license number'),
  isRegisteredBusinessName: Yup.boolean()
    .nullable()
    .required('Please select business registration'),
  operatingName: Yup.string().when('isRegisteredBusinessName', {
    is: (isRegistered) => {
      return isRegistered === 'true';
    },
    then: Yup.string().nullable().required('required'),
    otherwise: Yup.string().nullable()
  }),
  streetAddress: Yup.string().nullable().trim().required('Please select street address'),
  unitNumber: Yup.string().nullable().trim().max(25, 'Enter valid unit number'),
  city: Yup.string().nullable().trim().required('Please select city'),
  province: Yup.string().nullable().trim().required('Please select province'),
  country: Yup.string().nullable().trim().required('Please select country'),
  postalCode: Yup.string()
    .trim()
    .nullable()
    .required('Field is required')
    .test(
      'length-check',
      'Postal code must be 6 or 7 alphanumeric characters long, excluding spaces',
      (value) => {
        const sanitizedValue = value ? value.replace(/\s/g, '') : ''; // Remove spaces
        return sanitizedValue.length === 6 || sanitizedValue.length === 7; // Check length without spaces
      }
    )
    .matches(/^[A-Za-z0-9]{3} ?[A-Za-z0-9]{3,4}$/, 'Postal code format is invalid'), // Allows 'XXX XXX' or 'XXX XXXX'
  website: Yup.string().trim().nullable().max(50, 'URL is too long'),
  businessContactEmail: Yup.string()
    .trim()
    .nullable()
    .required('Email is required')
    .max(35, 'Email is too long')
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email'
    ),
  businessContactPhoneNumber: Yup.string()
    .nullable()
    .required('Please enter phone number')
    .validateNumber(),

  logo: Yup.string().nullable().trim()
};

const soleProprietorValidation = {
  residency: Yup.string().nullable().trim().required('Select residency')
};

const corporateValidation = {
  corporationNumber: Yup.string()
    .nullable()
    .trim()
    .max(25, 'Enter valid corporation number')
    .required('Corporation number required'),
  legalBusinessName: Yup.string()
    .nullable()
    .trim()
    .max(50, 'Enter valid business name')
    .required('Business name required'),
  jurisdiction: Yup.string().nullable().trim().required('Required')
};

const partnershipValidation = {
  partnershipNumber: Yup.string()
    .nullable()
    .trim()
    .max(25, 'Enter valid partnership number')
    .required('Required'),
  legalBusinessName: Yup.string()
    .nullable()
    .trim()
    .max(50, 'Enter valid business name')
    .required('Business name required'),
  jurisdiction: Yup.string().nullable().trim().required('Required')
};

export {
  principalBrokerInitValue,
  principalBrokerValidation,
  soleProprietorValidation,
  corporateValidation,
  partnershipValidation
};
