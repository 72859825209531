const getAccordionHeading = (pathname) => {
  if (pathname === '/dashboard') {
    return 'Complete your Profile';
  }
  if (pathname.includes('personal')) return 'Personal Details';
  if (pathname.includes('brokerage')) return 'Brokerage / Agent Info';
  if (pathname.includes('email')) return 'Email Confirmation';
  if (pathname.includes('final')) return 'Final Approval';
  if (pathname.includes('e-sign')) return 'Signature';
};

export { getAccordionHeading };
