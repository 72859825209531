import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import { useOutletContext } from 'react-router-dom';
import CustomPhoneInput from 'components/common/Fields/CustomPhoneInput';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FileDragDrop from 'components/FileDragDrop';
import MinioFileUpload from 'components/MinioFileUpload';
import { getUser } from 'utils/UserDetails';
import { UPDATE_FILE } from 'pages/Profile/common/Mutation';
import { useMutation } from '@apollo/client';
import CombineErrorMessage from 'components/CombineError';
import closeIcon from 'assets/icons/close.svg';
import { getLogoInitials } from 'utils/helper';

const BusinessInfo = () => {
  const [lmgLoading, setImageLoading] = useState(false);
  const [signedURL, setSignedURL] = useState('');
  const [file, setFile] = useState('');
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { userPersonalDetails } = useOutletContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.contactPhoneNumber && touched.contactPhoneNumber) ||
      (errors.contactEmail && touched.contactEmail) ||
      (errors.website && touched.website) ||
      (errors.logo && touched.logo)
    );
  };

  const user = getUser();

  const [uploadFile] = useMutation(UPDATE_FILE);

  const generatePresignedUrl = async (file) => {
    try {
      const { data } = await uploadFile({
        variables: {
          fileName: file.name,
          fileType: 'doc',
          userId: user.id
        }
      });
      return data.fileApiPresignedUrl;
    } catch (error) {}
  };

  const onFileChange = async (event) => {
    const { files } = event.target;

    if (!files || files.length === 0) {
      console.error('No files selected.');
      return;
    }

    const file = files[0];

    try {
      const { signedUrl, sourcePath } = await generatePresignedUrl(file);
      setImageLoading(true);
      setSignedURL(signedUrl);
      setFile(files[0]);
      setFieldValue('logo', sourcePath);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const getDocBlobURL = (key) => {
    return file && typeof file === 'object' ? URL.createObjectURL(file) : values.logo;
  };

  const renderLogo = () => {
    return (
      <div>
        {lmgLoading && (
          <MinioFileUpload
            signedURL={signedURL}
            file={file}
            onClose={() => setImageLoading(false)}
          />
        )}
        <div className="upload-image-container">
          <span onClick={closeLogo} className="image-cross-btn">
            <img src={closeIcon} alt="closeIcon" />
          </span>
          <img
            src={getDocBlobURL()}
            alt=""
            width={180}
            height={180}
            onLoad={() => setImageLoading(false)}
            className="circle-img mb-2 object-fit-cover"
          />
          <p className="text-center">
            <label htmlFor="logo_pic" className="text-primary cursor-pointer semi-bold">
              Upload
            </label>
          </p>
          <input
            className="d-none"
            id="logo_pic"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={onFileChange}
          />
        </div>
      </div>
    );
  };

  const closeLogo = () => {
    setImageLoading(true);
    setFieldValue('logo', '');
  };
  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <h3 className="card-heading">Business Information</h3>
          <Row>
            <Col md={6} lg={6}>
              <Field
                size="lg"
                id="businessContactPhoneNumber"
                name="contactPhoneNumber"
                label="Contact Phone Number"
                component={CustomPhoneInput}
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                name="contactEmail"
                label="Contact Email"
                component={FormikInputField}
                type="email"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                name="website"
                label="Website"
                component={FormikInputField}
                type="text"
                isError={false}
                isoptionallabel="true"
                isWebsiteOptions={true}
                websitePrefix={values.websitePrefix}
              />
            </Col>
          </Row>
          <Row>
            {values.logo ? (
              <Col md={12} className="text-center">
                <div className="d-flex justify-content-between">
                  <Label>Logo</Label>
                  <span className="text-muted font-size-14">Optional</span>
                </div>
                {renderLogo()}
              </Col>
            ) : (
              <Col md={12} className="text-center">
                <div className="d-flex justify-content-between">
                  <Label>Logo</Label>
                  <span className="text-muted font-size-14">Optional</span>
                </div>
                {user.id == userPersonalDetails.id ? (
                  <div>
                    <h1 id="circle-avatar">{getLogoInitials(values.accountName)}</h1>
                    <p className="text-center">
                      <label
                        htmlFor="logoPhoto"
                        className={`cursor-pointer semi-bold text-primary`}>
                        Upload Logo
                      </label>
                    </p>
                    <input
                      className="d-none"
                      id="logoPhoto"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onFileChange}
                    />
                  </div>
                ) : (
                  <h1 id="circle-avatar">{getLogoInitials(values.accountName)}</h1>
                )}
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default BusinessInfo;
