import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import infoIcon from 'assets/icons/info.svg';

const RejectProfileCard = ({ userPersonalDetails: userDetails }) => {
  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {userDetails.preferredName}!</h2>
      <Row>
        <Col md={5}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <h3 className="mb-0">
                  <FontAwesomeIcon className="text-danger me-2" icon={faTriangleExclamation} />
                  Updates or additional information required
                </h3>
              </div>
              <p className="font-size-18 my-4 semi-bold mb-3">
                We were unable to approve your profile, as there seems to be some discrepancies that
                requires your attention.
              </p>

              <div className="p-3 bg-light mt-2">
                <div className="d-flex">
                  <div>
                    <img
                      src={infoIcon}
                      alt="info icon"
                      style={{ height: '20px', width: '20px' }}
                      className="me-2"
                    />
                  </div>
                  <p className="mb-0">{userDetails.finalApprovalComment}</p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RejectProfileCard;
