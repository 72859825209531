import React from 'react';
import { GET_CONNECTIONS } from 'pages/Agent/Graphql';
import { getUser } from 'utils/UserDetails';
import { useQuery } from '@apollo/client';
import { Card, CardBody } from 'reactstrap';
import Avatar from 'components/Avatar';
import { useMediaQuery } from 'react-responsive';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { useParams } from 'react-router-dom';

const InvestorsOfMa = () => {
  const { id } = useParams();
  const user = getUser();
  const { data, loading } = useQuery(GET_CONNECTIONS, {
    variables: {
      parentId: Number(id || user.id)
    }
  });
  const investorList = data?.getConnections?.connections ? data?.getConnections?.connections : [];
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  return (
    <Card>
      <CardBody>
        <h3 className="mb-3">Investors</h3>
        {investorList?.map((item) => {
          const { email, firstName, lastName, id, profilePicture } = item.child || {};
          return (
            <div
              key={id}
              className="d-flex justify-content-between p-4 mb-3"
              style={{ background: '#F8F8F8' }}>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <Avatar
                    fname={firstName}
                    lname={lastName}
                    image={profilePicture}
                    size={60}
                    fontsize="20"
                  />
                </div>
                <div>
                  <h5
                    className={`font-size-16 mb-1 ${isMobile ? 'show-email-text-ellipses' : ''}`}
                    style={{ width: isMobile ? '180px' : '' }}>
                    <span className="font-size-18">{`${firstName} ${lastName}`}</span>
                  </h5>
                  <p
                    className={`mb-0 ${isMobile ? 'show-email-text-ellipses' : ''}`}
                    style={{ width: isMobile ? '180px' : '' }}>
                    {email}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        {!loading && !investorList.length ? 'No Investors' : null}
      </CardBody>
    </Card>
  );
};

export default InvestorsOfMa;
