import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { businessInfoInitValue, businessInfoValidation } from '../../Helper';
import AccountType from './components/AccountType';
import BusinessAddress from './components/BusinessAddress';
import BusinessDetails from './components/BusinessDetails';
import BusinessType from './components/BusinessType';
import PrincipalRepresentative from './components/PrincipalRepresent';
import Identification from 'pages/Profile/common/Identy';
import { Button, Col, Row } from 'reactstrap';
import BusinessContact from './components/BusinessContact';
import { useMutation, useQuery } from '@apollo/client';
import { BUSINESS_INFO, UPDATE_BUSINESS_INFO } from '../../MutationGraphQL';
import { getUser } from 'utils/UserDetails';
import { GET_BUSINESS_INFO } from './BusinessInfoGraphQL';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import {
  APPROVED_KEY,
  BROKERAGE_DETAILS_KEY,
  BUSINESS_INFO_KEY,
  PENDING_KEY,
  REJECTED_KEY
} from 'constant';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  checkIfFormValueExistInData,
  getUpdateValuesFromObj,
  updateMAApproveSection
} from 'pages/Profile/Helper';
import ErrorScroll from 'components/ErrorScroll';
import Loader from 'components/Loader';
import { deleteDocandProfileImg, trimAllFields } from 'utils/helper';
import { ADD_PERSONAL_INFO } from 'pages/Profile/components/Investor/components/PersonalInfo/PersonalInfoGraphQL';
import { UserDetailsContext } from 'context/UserDetail';
import NewBusinessDataForm from './components/NewBusinessDataForm';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import {
  businessAddKeys,
  businessContactKeys,
  businessPartnerDetailsKeys,
  maIdentyKeys,
  maSecondIdentyKeys
} from './components/NewBusinessDataForm/Helper';

const BusinessInfo = () => {
  const [initialValues, setInitValues] = useState(businessInfoInitValue);
  const { userPersonalDetails } = useOutletContext();
  const { id } = useParams();
  const { state: params, pathname } = useLocation();
  const navigate = useNavigate();
  const user = getUser();
  const [businessInfo] = useMutation(BUSINESS_INFO);
  const [updateBusinessInformation] = useMutation(UPDATE_BUSINESS_INFO);
  const [approveSection, { loading: approveLoading }] = useMutation(APPROVE_USER_BY_ADMIN);
  const [updateProfilePicture] = useMutation(ADD_PERSONAL_INFO);
  const userDetails = useContext(UserDetailsContext);
  const { data: businessInfoData } = useQuery(GET_BUSINESS_INFO, {
    variables: {
      userId: parseInt(id || user.id)
    }
  });

  const isCompleteProfile = pathname.includes('complete-profile');
  const isProfile = pathname.includes('profile');
  const isApproval = pathname.includes('approval');

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: BUSINESS_INFO_KEY
    }
  });

  const newFormData = data?.getSectionUpdatedFields?.updatedFields || {};

  useEffect(() => {
    if (businessInfoData?.getBusinessInfoDetails) {
      const { postalCode } = businessInfoData.getBusinessInfoDetails;

      setInitValues({
        ...businessInfoData?.getBusinessInfoDetails,
        postalCode: postalCode || ''
      });
    }
    setInitValues((pre) => ({
      ...pre,
      profilePicture: userDetails.profilePicture
    }));
  }, [businessInfoData, userDetails]);

  const updateBusinessInfo = async (data) => {
    let formData = { ...data };
    delete formData.id;
    delete formData.profilePicture;
    delete formData.identificationDocs;
    delete formData.secondaryIdentificationDocs;

    if (formData.logo === initialValues.logo) {
      delete formData.logo;
    }
    if (formData.identificationFrontDocs === initialValues.identificationFrontDocs) {
      delete formData.identificationFrontDocs;
    }
    if (
      formData.secondaryIdentificationFrontDocs === initialValues.secondaryIdentificationFrontDocs
    ) {
      delete formData.secondaryIdentificationFrontDocs;
    }

    if (data.profilePicture !== initialValues.profilePicture) {
      await updateProfilePicture({
        variables: {
          profilePicture: data.profilePicture,
          updatePersonalDetailsId: user.id
        }
      });
    }

    formData = deleteDocandProfileImg(formData, initialValues);
    delete formData.userId;
    const allValues = getUpdateValuesFromObj(initialValues, formData);
    if (!isCompleteProfile && isProfile && userPersonalDetails.approvedStatus === APPROVED_KEY) {
      allValues.isUpdating = true;
      allValues.formSection = BUSINESS_INFO_KEY;
    }
    try {
      const castValue = Yup.object(businessInfoValidation).cast(allValues);
      const { data: result } = await updateBusinessInformation({
        variables: {
          userId: parseInt(user?.id),
          businessInfoInput: {
            accountType: formData.accountType,
            ...castValue,
            isRegisteredBusinessName: allValues.isRegisteredBusinessName
          }
        }
      });
      navigate(`/profile/lending-criteria`);
      toast.success(result.updateBusinessInfo.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = async (data) => {
    const trimmedValues = trimAllFields(data);

    if (trimmedValues && !trimmedValues.isRegisteredBusinessName) {
      trimmedValues.operatingName = null;
    }

    if (trimmedValues.website) {
      trimmedValues.website = trimmedValues.website.trim();
      if (!trimmedValues.websitePrefix) {
        trimmedValues.websitePrefix = 'https://';
      }
      const hasHttpPrefix = trimmedValues.website.startsWith('http://');
      const hasHttpsPrefix = trimmedValues.website.startsWith('https://');
      if (hasHttpPrefix || hasHttpsPrefix) {
        trimmedValues.website = trimmedValues.website.replace(/^https?:\/\//, '');
        trimmedValues.websitePrefix = hasHttpPrefix ? 'http://' : 'https://';
      }
    }

    if (pathname.includes('approval')) return onApproveBusinessInfo(trimmedValues);
    if (userPersonalDetails?.isBusinessInfo) return updateBusinessInfo(trimmedValues);
    try {
      const formData = { ...trimmedValues };
      delete formData.profilePicture;
      const castValue = Yup.object(businessInfoValidation).cast(formData);
      const { data: result } = await businessInfo({
        variables: {
          userId: parseInt(user.id),
          businessInfoInput: {
            ...castValue,
            isRegisteredBusinessName: formData.isRegisteredBusinessName
          }
        }
      });
      await updateProfilePicture({
        variables: {
          profilePicture: trimmedValues.profilePicture,
          updatePersonalDetailsId: user.id
        }
      });
      toast.success(result?.addBusinessInfo.message);
      navigate(`/complete-profile/lending-criteria`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onApproveBusinessInfo = async (data) => {
    const approveValue = data.approveValue === 'true' ? true : false;
    try {
      const { approveSections } = userPersonalDetails;
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updateMAApproveSection(approveSections, BUSINESS_INFO_KEY, approveValue),
          status: approveValue ? 'approved' : 'rejected',
          formSection: BUSINESS_INFO_KEY
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      navigate(`/approval/mac/${id}/lending-criteria`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderSubmitButton = (isSubmitting, dirty, isValid) => {
    return isSubmitting ? (
      <Loader />
    ) : (
      <Button color="primary" type="submit" size="lg">
        {pathname.includes('complete-profile') ? 'Save and continue' : 'Update'}
      </Button>
    );
  };

  const renderFormActionButtons = (isSubmitting, dirty, isValid) => {
    if (
      pathname.includes('approval') &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <>
          <Button
            color="danger"
            className="ms-3"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}>
            Reject
          </Button>
          <Button
            color="primary"
            className="ms-3"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: true
            }}>
            Approve Section
          </Button>
        </>
      );
    }
    if (pathname.includes('profile')) {
      return renderSubmitButton(isSubmitting, dirty, isValid);
    }
  };

  const checkBusinessPartnerDetails =
    pathname.includes('approval') &&
    checkIfFormValueExistInData(newFormData, businessPartnerDetailsKeys);

  const checkAccountType =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, ['accountType']);
  const checkBussinessType =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, ['bussinessType']);
  const checkBusinessAdd =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, businessAddKeys);
  const checkBusinessContact =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, businessContactKeys);
  const checkPosition =
    pathname.includes('approval') &&
    checkIfFormValueExistInData(newFormData, ['principlerepresentativePosition']);
  const checkPrimaryIdenty =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, maIdentyKeys);
  const checkSecondIdentyKeys =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, maSecondIdentyKeys);

  const renderNewDataForm = () => {
    if (
      isApproval &&
      userPersonalDetails.isUnderReviewed &&
      userPersonalDetails.approveRequiredSections[BUSINESS_INFO_KEY]
    ) {
      return (
        <Col>
          <NewBusinessDataForm
            userPersonalDetails={userPersonalDetails}
            initialValues={initialValues}
            approveLoading={approveLoading}
            onApproveBusinessInfo={onApproveBusinessInfo}
            newFormData={newFormData}
            checkBusinessPartnerDetails={checkBusinessPartnerDetails}
            checkAccountType={checkAccountType}
            checkBussinessType={checkBussinessType}
            checkBusinessAdd={checkBusinessAdd}
            checkBusinessContact={checkBusinessContact}
            checkPosition={checkPosition}
            checkPrimaryIdenty={checkPrimaryIdenty}
            checkSecondIdentyKeys={checkSecondIdentyKeys}
          />
        </Col>
      );
    }
  };

  return (
    <Row>
      <Col>
        <Formik
          initialValues={initialValues}
          validationSchema={
            pathname.includes('approval')
              ? Yup.object().shape({})
              : Yup.object(businessInfoValidation)
          }
          enableReinitialize={true}
          onSubmit={onSubmit}
          validateOnMount={true}>
          {({ handleSubmit, isSubmitting, setFieldValue, values, dirty, isValid }) => (
            <form
              onSubmit={(e) => {
                const submitter = e.nativeEvent?.submitter;
                if (submitter?.name && submitter?.value && pathname.includes('approval')) {
                  setFieldValue(submitter.name, submitter.value);
                }
                handleSubmit(e);
              }}>
              <ErrorScroll />
              {/* <AccountType /> */}
              <div className="mt-3">
                <BusinessType isOldData={checkBussinessType} />
              </div>
              {values?.bussinessType ? (
                <>
                  <div className="mt-3">
                    <BusinessDetails
                      isOldData={checkBusinessPartnerDetails}
                      title="Mortgage Administrator License Number"
                    />
                  </div>
                  <BusinessAddress isOldData={checkBusinessAdd} />
                  <BusinessContact isOldData={checkBusinessContact} />
                  <PrincipalRepresentative isOldData={checkPosition} />
                  <div className="mt-3">
                    <Identification
                      title="Principal Representative Identification"
                      formKey={{
                        forntDoc: 'identificationFrontDocs',
                        backDoc: 'identificationBackDocs',
                        identyType: 'identificationType',
                        identyNum: 'identificationNumber'
                      }}
                      identyTypesKey={{
                        primary: 'identificationType',
                        secondary: 'secondaryIdentificationType'
                      }}
                      isOldData={checkPrimaryIdenty}
                    />
                  </div>
                  <div className="mt-3">
                    <Identification
                      title="Principal Representative Secondary Identification"
                      formKey={{
                        forntDoc: 'secondaryIdentificationFrontDocs',
                        backDoc: 'secondaryIdentificationBackDocs',
                        identyType: 'secondaryIdentificationType',
                        identyNum: 'secondary_identification_number'
                      }}
                      secondary
                      identyTypesKey={{
                        primary: 'identificationType',
                        secondary: 'secondaryIdentificationType'
                      }}
                      isOldData={checkSecondIdentyKeys}
                    />
                  </div>
                  <div className="text-end mt-4">
                    {renderFormActionButtons(isSubmitting, dirty, isValid)}
                  </div>
                </>
              ) : null}
            </form>
          )}
        </Formik>
      </Col>
      {renderNewDataForm()}
    </Row>
  );
};

export default BusinessInfo;
