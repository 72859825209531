import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import { getCanadaStates } from 'utils';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import CombineErrorMessage from 'components/CombineError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const BusinessDetails = ({
  isNewData,
  isOldData,
  licenseKey = 'maLicenceNumber',
  title = 'Brokerage License Number'
}) => {
  const [hasError, setHasError] = useState(false);
  const { errors, touched, values } = useFormikContext();

  useEffect(() => {
    const err = checkError();
    setHasError(err);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors[licenseKey] && touched[licenseKey]) ||
      (errors.legalBusinessName && touched.legalBusinessName) ||
      (errors.corporationNumber && touched.corporationNumber) ||
      (errors.partnershipNumber && touched.partnershipNumber) ||
      (errors.jurisdiction && touched.jurisdiction) ||
      (errors.residency && touched.residency) ||
      (errors.operatingName && touched.operatingName)
    );
  };

  const getHeading = () => {
    switch (values.bussinessType) {
      case 'partnership':
        return 'Partnership Details';
      case 'corporation':
        return 'Corporate Details';
      case 'sole_proprietor':
        return 'Business Details';
      default:
        break;
    }
  };

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0 position-relative">
            <h3 className="card-heading">{getHeading()}</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>

          <div className="input-spacing">
            <Field
              size="lg"
              name={licenseKey}
              label={title}
              component={FormikInputField}
              type="text"
              isError={false}
            />
          </div>
          {values?.bussinessType !== 'sole_proprietor' && (
            <Row>
              <Col md={6} lg={6} className="input-spacing">
                <Field
                  size="lg"
                  name="legalBusinessName"
                  label="Legal Business Name"
                  component={FormikInputField}
                  type="text"
                  isError={false}
                />
              </Col>
              {values?.bussinessType === 'corporation' && (
                <Col md={6} lg={6} className="input-spacing">
                  <Field
                    size="lg"
                    name="corporationNumber"
                    label="Corporation Number"
                    component={FormikInputField}
                    type="text"
                    isError={false}
                  />
                </Col>
              )}
              {values?.bussinessType === 'partnership' && (
                <Col md={6} lg={6} className="input-spacing">
                  <Field
                    size="lg"
                    name="partnershipNumber"
                    label="Partnership Number"
                    component={FormikInputField}
                    type="text"
                    isError={false}
                  />
                </Col>
              )}
              <Col md={6} lg={6} className="input-spacing">
                <Field
                  size="lg"
                  name="jurisdiction"
                  label={
                    values?.bussinessType === 'corporation'
                      ? 'Jurisdiction of Incorporation / Formation'
                      : 'Jurisdiction of Formation'
                  }
                  component={FormikSelectField}
                  options={getCanadaStates()}
                  type="select"
                  keys={{
                    id: 'name',
                    label: 'name'
                  }}
                  isError={false}
                />
              </Col>
            </Row>
          )}

          {values?.bussinessType === 'sole_proprietor' && (
            <Row className="input-spacing">
              <Col md={6} lg={6}>
                <Field
                  size="lg"
                  name="residency"
                  label="Residency"
                  component={FormikSelectField}
                  options={[{ id: 'ontario', label: 'Ontario' }]}
                  type="select"
                  keys={{
                    id: 'id',
                    label: 'label'
                  }}
                  isError={false}
                />
              </Col>
            </Row>
          )}
          <div className="input-spacing">
            <Label>Does the business operate under a registered business name?</Label>
            <div className="d-flex">
              {answer.map(({ label, value }, index) => {
                return (
                  <div key={label} className={index === 1 ? 'ms-3' : ''}>
                    <Field
                      name="isRegisteredBusinessName"
                      type="radio"
                      label={label}
                      value={value}
                      checked={
                        index === 1
                          ? !values.isRegisteredBusinessName
                          : values.isRegisteredBusinessName
                      }
                      component={FormikRadioField}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {values.isRegisteredBusinessName && (
            <div className="input-spacing">
              <Field
                size="lg"
                name="operatingName"
                label="Operating Name"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default BusinessDetails;
