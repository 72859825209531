import React from 'react';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';

const SelectionOfMA = ({ userPersonalDetails, children }) => {
  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {userPersonalDetails.preferredName}!</h2>
      <Row>
        <Col md={6} lg={6}>
          <Card>
            <CardBody>
              <h3>Congrats! One more step to start investing.</h3>
              <p className="font-size-18 my-4 semi-bold">
                Investors that are not licensed through FSRA will need to invest through a Mortgage
                Administrator, Mortgage Investment Firm or Brokerage. Before we can submit your
                account for review, you will need to enroll with a Mortgage Administrator.
              </p>
              <Label className="mb-3">Here are a few recommended Administrators:</Label>
              {children}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectionOfMA;
